.testimonial-slider {
	
	border-radius: .5rem;
	padding: 6rem 10rem 5rem 10rem;
	
	@media (max-width: 800px) {
		
		padding: 6rem 3rem;
		
	}
	
	&__slide {
		img {
		    max-width: 330px;
		}
	}

	.col-lg-4 {
		
		align-items: center;
		display: flex;
		justify-content: center;
		
		@media (max-width: 1024px) {

			align-items: flex-start;
			padding-bottom: 4rem;
			justify-content: flex-start;
			width: 100%;

			img {
				
				// height: 100%;
				object-fit: cover;
			
			}
		}
		
		img {
			
			max-width: 100%;
		
		}
		
	}
		
	.testimonial__quote {
		
    	font-family: "Source Sans Pro",sans-serif;
		font-size: 2.6rem;
		line-height: 3.4rem;
		display: inline-block;
		margin-bottom: 2.5rem;


		@media (max-width: 1024px) {
			line-height: 2.8rem;
		}
	}
	
	@media (max-width: 800px) {

		.testimonial > * {
			
			display: block;
			margin-bottom: 2rem !important;
			
		}

	}
	
	.btn--teal {
		
		color: #fff !important;
		margin-top: 1rem;
		
		&:after {
			
			content: url(../../assets/images/arrow-right-white.svg);
			
		}
		
	}
	
	.link {
		
		color: #fff;
		display: block;
		padding-top: 3rem;
		
		@media (max-width: 800px) {

			padding-bottom: 2rem;
			padding-top: 1rem;
		
		}
		
		&:after {
			
			content: url(../../assets/images/arrow-right-white.svg);
			display: inline-block;
			left: 2rem;
			position: relative;
			top: .2rem;
		
		}
		
	}
	.slick-arrow{
		&.slick-prev{
			padding-left: 1.5rem;
			&::before{
				background: url("../../assets/images/arrow-left-white.svg") no-repeat;
			}
			
		}
		&.slick-next{
			padding-right: 1.5rem;
			&::before{
				background: url("../../assets/images/arrow-left-white.svg") no-repeat;
			}

			@media (max-width: 768px) {
			    left: 10rem;
			    padding-bottom: 0.5rem;
			}
		}
		&::before{
			
			height: 5rem;
			width: 5rem;
		}
	}
	
}

.page-template-template-working-for-us {
	.testimonial-slider {
		&__slide {
			img {
				width:	100%;
			    max-width: 330px;
			}
		}
	}
}