//******************************************************************************
// SECTIONS
// Styles for the various sections used throughout the site
//******************************************************************************

$pbPadding: 7rem;
$pbPadding-tablet: 6rem;


// TO REMOVE
.block {
	padding-top: $pbPadding;
	padding-bottom: $pbPadding;
}

.container {
	//background-color: red !important;
}

.inner-spacing > * + * {
	margin-top: 2rem;
	margin-bottom: 0;
}

//SITEWIDE - VIDEO
.video-container {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
    padding-bottom: 40%;
    overflow: hidden;
    z-index: 1;

    iframe {
    	background-color: #000;
    z-index: 10000;
    // position: relative;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__play {
    	width: 6rem;
	    height: 6rem;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    font-size: 0;
	    border: 0;

    	&:focus {		
			outline: none;
	  		box-shadow: none;
		}
	    &:before {
	    	position: absolute;
	    	content: '';
	    	z-index: 1;
	    	width: 0;
			height: 0;
			margin-left: 2px;
			border-style: solid;
			border-width: 9px 0 9px 12px;
			border-color: transparent transparent transparent white;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	    &:after {
	    	position: absolute;
	    	content: '';
	    	width: 80px;
	    	height: 80px;
	    	border-radius: 80px;
	    	display: block;
	    	background: var(--textclr);
	    	top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
	    }
    }
}

.text-image {
	.video-container {
		padding-bottom: 60%;
	}
}

.reveal-content {
	display: none;
	line-height: 3rem;
}

// GRIDDER
.gridder{
	font-size:0; // FIX
	.gridder__toggle {
	    display: inline-block;
	    vertical-align: top;
	    margin-bottom: 50px;
	    margin-right: 20px;

	    .link {
	    	&:before {
	    		content:"Read more";
	    	}
	    }
	    &.selectedItem {
			.link {
				&:before {
					content: "Read less";
				}
			}
		}
	}

	&--three {
		.gridder__toggle {
			width: calc(33% - 10px);

		    /*&:nth-of-type(3n + 3) {
				margin-right: 0;
			}*/

		    @media(max-width: 1366px) {
		    	width: calc(50% - 10px);

		    	&:nth-of-type(3n + 3) {
					margin-right: 10px;
				}

				&:nth-of-type(2n + 2) {
					margin-right: 0;
				}
		    }
		    @media(max-width: 767px) {
		    	width: 100%;
		    	margin-right: 0;

		    	&:nth-of-type(3n + 3) {
					margin-right: 0;
				}
		    }
		}
	}

	&--four {
		.gridder__toggle {
			width: calc(25% - 15px);

			&.desktop-last-in-row {
				margin-right: 0;
			}

			@media(max-width: 1100px) {
				&.tablet-last-in-row {
					margin-right: 0;
				}
			}

			@media(max-width: 1100px) {
		    	width: calc(50% - 10px);

		    	&:nth-of-type(4n + 4) {
					// margin-right: 15px;
				}

				&:nth-of-type(2n + 2) {
					// margin-right: 0;
				}
		    }
		    @media(max-width: 767px) {
		    	width: 100%;
		    	margin-right: 0;

		    	&:nth-of-type(4n + 4) {
					margin-right: 0;
				}
		    }
		}
	}

	.gridder__content {
		margin-bottom: 20px;
		font-size: 1.6rem;
	}
	.cta--gridder {
		min-width: 0;
	}

	.gridder-content {
	    display: none;
	}
	.gridder-expanded-content {
		font-size: 1.6rem;
		padding: 70px 20px;
		border-radius: 6px;
		text-align: center;
		@media (max-width: 768px) {
			padding: 2rem 2rem 3rem 2rem;
		}
	}
	.gridder-show { 
		display: block;
		float: left;
		width: 100%;
		position: relative;
		margin-bottom: 40px;
	}
}

.social-shares {
	img,
	svg {
		width: 2.5rem;
		height: 2.5rem;
	}
}

.map-container {
	iframe {
		width: 100%;
	}
}

.google-review-block{
	.google-wrapper{
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;
		.stars{
			margin-left: 15px;
		}

	}	
}


.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  
  display: inline-block;
  font-size: 3.5rem;
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;
  
  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, #F8B805 var(--percent), white var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.google-wrapper {
	margin-bottom: 1em;
}
.logo-bar__logos{
	transition: 0.7s;
}