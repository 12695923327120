.site-header {

	box-shadow: 0 0 2rem #000;
	position: relative;
	z-index: 99;
	position: sticky;
	top: 0;
	@media (min-width: 1350px) {
		
		padding: 1.1rem 0;	
		
	}
	
	.navbar {

		position: static;
	
		@media (max-width: 1350px) {
		
			padding: 1.5rem 1.5rem 1.5rem 1.5rem;
			justify-content: space-between;
			padding-right: 70px;
		
		}
		@media (max-width: 768px) {
			
		}

		@media (max-width: 390px) {
			padding-right: 4rem;			
		}

		&-brand {

			position: relative;
			padding-right: 4rem;
			margin-right: 3rem;
			
			@media (max-width: 1350px) {
			
				// position: absolute;
				// top: 2.2rem;
			
			}	


			@media (max-width: 768px) {
				padding-right: 0;
				// margin-right: 7rem;
								
			}

			@media (max-width: 365px) {
				margin-right: 1rem;			
			}
			
			&:after {
			
				border-right: .2rem solid #31beaf;
				bottom: 0;
				height: 70%;
				right: 0;
				position: absolute;
				top: 15%;
				
				@media (min-width: 1820px) {
				
					content: '';
				
				}
				
				@media (max-width: 1350px) and (min-width: 767px)  {
					
					content: '';
					
				}
												
			}
			
			@media (max-width: 1350px) {
			
				// left: 1.5rem;
				// position: absolute;
				// top: 2rem;
				width: 9rem;
				
				&:after {
				
					display: none;
				
				}
					
				.logo {
				
					width: 100%;
				
				}
			
			}

		}
		
		.logo-strap {
			
			display: none;
			a{
				text-decoration: none;
				color: unset;
			}
			
			@media (min-width: 1820px) {
			
				display: block;
			
			}
			
			@media (max-width: 1350px) and (min-width: 767px)  {
				
				display: block;	
				// left: 19rem;
				// position: absolute;
				// top: 2.2rem;
				
			}
			
		}
		@media (max-width: 1350px) {
			#primary-navigation {
				box-shadow:  inset 0px .7rem 2rem -2rem #333;
				position: absolute;
				top: 100%;
				left: 0px;
				right: 0px;
				background: white;
				margin: 0;
				&>div{
					padding: 3rem 3.5rem;

				}
			}
		}
		
		.navbar-toggler {
		
			top: 50%;
			transform: translateY(-50%);
		
		}
		
	}
		
	ul, li {
		
		margin: 0;
		padding: 0;
		white-space: nowrap;
		
		a {
			
			font-size: 1.4rem;
			white-space: nowrap;
			
		}
		
	}
	
	li {
		
		margin-right: 4rem;
		
		@media (max-width: 1540px) {
			font-size: 1.3rem;
			margin-right: 2rem;

			&:last-child {
				margin-right: 4rem;
			}

		}

		@media (max-width: 1350px) {
		
			margin: 0;
			padding: 1rem 0;

			&:last-child {
				margin-right: 0;
			}
		
		}
	
		a {
			
			font-weight: 700;
			text-decoration: none;

			@media (max-width: 1540px) {
				font-size: 1.3rem;

			}
			
			@media (max-width: 1350px) {
			
				display: flex;
				justify-content: space-between;
				
				.caret {

					background: url(../../assets/images/arrow-right-blue.svg);
					display: block;
					height: 1.2rem;
					width: 0.8rem;
					
				}
			
			}

		}

		&.menu-item-has-children.show > a .caret {
			background: url(../../assets/images/arrow-right-green.svg);
			transform: rotate(-90deg);
		}

		&.menu-item-has-children > a .caret,
		&.menu-item-has-children.current_page_parent > a .caret {
			transform: rotate(90deg);
		}
		
		&.current_page_parent > a,
		&.current_page_item > a,
		&.current-menu-item > a		{

			color: #31BEAF;
			
			@media (min-width: 1350px) {
			
				border-bottom: .3rem solid #31BEAF;
				padding-bottom: 3.2rem;
				
			}

		}
		
		@media (max-width: 1350px) {
		
			&.current_page_parent {
			
				.dropdown-menu {
				
					// display: block;
				
				}
				
			}
				
		}
		
		@media (min-width: 1350px) {
		
			&.show > a,
			&:hover > a,
			&.active > a {

				border-bottom: .3rem solid #31BEAF;
				padding-bottom: 3.5rem;

			}
			
		}
		
		/* Take account of the 'Make a payment' button wrapping */
		
		@media (min-width: 1539px) {
		
			&.show > a,
			&:hover > a,
			&.active > a,
			&.current_page_parent a,
			&.current_page_item a {	

				padding-bottom: 3.5rem;

			}
		
		}
		
	}
	
	.dropdown-menu {
		
		background: #fff !important;
		
		@media (min-width: 1350px) {
		
			margin: 3.4rem 0 0 -2.5rem !important;
			padding: 2rem 0 !important;
			
		}
			
		li.container {
			
			padding: 0 !important;
			
		}
		
		ul, li {
			
			width: 100%;
			
		}
		
		.nav-megamenu__menu {
		
			padding: 0;
		
			li {
			
				margin-right: 4rem;
				padding: 1rem 3rem;
				
				@media (max-width: 1350px) {
				
					margin: 0;
					padding: 1.5rem 0 0 2rem;
				
				}
				
				&:after {

					content: url(../../assets/images/arrow-right-blue.svg);
					float: right;
					
					@media (max-width: 1350px) {
					
						position: absolute;
						right: 0;
						top: .7rem;
					
					}

				}
				

				&:hover:after {
					
					content: url(../../assets/images/arrow-right-green.svg);
					
				}

				&.hide-chevron {
					&:after,
					&:hover:after {
						content: '';
					}
				}
			
			}
			
		}
		
		li {
		
			font-size: 1.5rem;
			list-style-type: none;
			margin: 0;
	
			width: 100%;
			
			a {
				
				border: none;
				font-weight: 500;
				padding-right: 2rem;
				
			}
			
		}
		
		ul.row {
			
			margin: 0;
			padding: 0;
		
		}
		
	}
	
	.nav-megamenu__menu {
		
		ul {
			
			padding: 0;
		}
		
		li {
			
			padding: .5rem 1rem;
			
		}
		
	}
	
	&__menu {
		position: relative;
		z-index: 3;

		margin-right: 1rem;
		
		@media (max-width: 1540px) {
			font-size: 1.3rem;
		}

		@media (max-width: 1350px) {
		
			// position: absolute;
			// right: 9.5rem;
			// top: 1.5rem;
		
		}

		@media (max-width: 768px) {
			font-size: 1.2rem;
		}
	
	}
	
	.mobile-call-menu {
		position: relative;
		z-index: 1;
		background: white;

		.icon {
			display: block;
			margin: 0 0 0 auto;
			background: none;
			border: none;
			font-size: 0;
			position: relative;
		    width: 2.5rem;
		    height: 2.5rem;

			a {
			    width: 100%;
			    height: 100%;
			    display: block;
			    z-index: 999999;
			    position: relative;
			}

			&::after {
			    content: '';
			    width: 100%;
			    height: 100%;
			    position: absolute;
			    background-image: url("/wp-content/themes/rouge-wp-start-theme/assets/images/phone-icon.svg");
			    left: 0;
			    top: 0;
			}
			
		}

		ul {
			list-style: none;
			padding-top: 1rem;

			li {
				padding: 1rem;

				span {
					width: 50%;
				    display: inline-block;					

				    a {
				    	display: inline;
				    }
				}
			}

		}
		
		@media (max-width: 350px) {
		
			display: none;
		
		}
		
		@media (min-width: 1350px) {
			
			display: none;	
			
		}

	}

}

.direct-security-toggle {
	.site-header {
		.navbar {
			&-brand {
				&:after {
					display: none;					
				}
			}
		}
	}
}

.page-template-template-pagebuilder {
	.site-header {
		.navbar {
			@media (max-width: 1350px) {
				padding: 1.5rem;
			}
		}
	}
}