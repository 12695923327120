.page-template-template-direct-security-grid {
		
	.card {
	
		&__link {

			font-size: 3rem;
			font-weight: 400;
			text-decoration: none;

		}
		
		&__icon--image {
			
			width: 100%;
			
		}
				
		&__icon:not(.card__icon--image) {

			padding-top: 2rem;

		}

		h4, p {

			margin: 0 auto;
			max-width: calc(100% - 6rem);

		}
		h4{
			margin-bottom: 2rem;
		}
		
		&__frontside {

			padding: 0;
			padding-bottom: 2rem;

			img {

				margin-bottom: 3rem;
				min-height: 150px;
				
			}

		}

		&__backside {

			padding: 3rem 0;

		}
		
	}
	.bg--blue.hero, .bg-blue.hero{
		background: #1A507A;
	}
}