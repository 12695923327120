body.blog,
body.page-template-template-dss-news {
	
	@media (max-width: 768px) {
	
		.hero {

			padding: 4rem 0 20rem 0;
			
		}
											
	}
	
}
.sticky-wrapper {
	&:not(.sticky-sidebar){
		height: 100%;
	}
	img {
		display: block;
		width: auto;
		max-width: 100%;
		height: auto;
		// max-height: 375px;
		object-fit: cover;
	}
	@media screen and (min-width: 1820px){
		img {
			// max-height: 310px;
		}
	}
	@media screen and (max-width: 1349px){
		img {
			display: block;
			width: 100%;
			max-width: 100%;
			height: auto;
			max-height: 650px;
			+ .btn {
				margin-bottom: 2rem;
			}
		}
	}
	@media (max-width: 768px) {
		img {
			width: 100%;
			// max-height: 250px;
		}
	}
}
.sticky-sidebar{
	&:not(.sticky-wrapper){
		@media screen and (min-width: 1820px){
			width: 100%;
			margin-right: 5rem;
		}
	}
	.social-shares ul {
		
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		
		@media (max-width: 768px) {
			
			margin-bottom: 6rem;
			
		}
		
			li {
			
				align-items: center;
				background: #4267B2;
				border-radius: .5rem;
				display: flex;
				height: 3.8rem;
				justify-content: center;
				list-style-type: none;
				margin-right: 1rem;
				width: 3.8rem;
				
				svg {
				
					color: #fff;
					width: 1rem;
				
				}
			
				&:nth-child(2) {
				
					background: #55ACEE;
				
					svg {
					
						width: 1.5rem;
					
					}
				
				}
			
				&:nth-child(3) {
				
					background: #0077B5;
				
					svg {
					
						width: 1.2rem;
					
					}
				
				}
			
				&:nth-child(4) {
				
					background: #7D7D7D;
				
					svg {
					
						width: 1.5rem;
					
					}
				
				}

			
			}
		
		}

		@media screen and (min-width: 1350px){
			position: sticky;
			top: 14rem;
		}

		
	
	
	
}

.blog--listing {
	
	padding-top: 5rem;
	
	@media (max-width: 768px) {
							
		.row-reverse {

			display: flex;
			flex-direction: column-reverse;
			
		}

	}
	
	.inner-spacing {
		
		// margin-bottom: 3rem;
		padding-bottom: 6rem;
		padding-top: 0 !important;

		.row.cards > .col-lg-12.inner-spacing {
			padding-bottom: 0;
		}
		
	}
	
	.cards__date,
	.cards__category {
		
		font-size: 1.3rem;
		margin: 1rem 0 -1rem 0;

		a {
			text-decoration: none;
			color: inherit;

			&:hover {
				text-decoration: underline;
			}
		}

		span.seperator:last-child {
			display: none;
		}
	}
	
	.cards {
		
		h3 a {
			
			text-decoration: underline;
			text-decoration-thickness: .1rem;
			
		}
		
	}
	
	.btn {
		
		bottom: 0;
		position: absolute;
		left: 1.4rem;
		
	}
	
	.form--filter {
	
		margin-bottom: 6rem;
	
		@media (max-width: 768px) {
										
			// margin: -20rem 0 4rem 0;

		}		
			
	}
	
}