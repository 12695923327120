body.home {
	.letter-wrapper {
		@media (max-width: 800px) {
			margin-left: 0;
		}
	}
}

.pull-out-cta {
	overflow: hidden;
	border-radius: .5rem;
	
	&.negative-bottom-margin {
		margin-bottom: 0rem;
		@media screen and (min-width: 1024px){
			margin-bottom: -2rem;
		}
	}
	
	&__left {
		position: relative;
		z-index: 3;
		padding: 3rem 3rem 0 3rem;
	}

	&__right {
		position: relative;
		z-index: 1;
	}

	.btn {
		
		margin: 3rem 0;
		
	}

	#lottie-envelope-animation--hp {
		position: relative;;
		bottom: -10rem;
		width: 100% !important;
		height: auto !important;
		margin-top: -18rem;
		opacity: 0;
		transition: opacity 0.5s ease;

		&.elementScrolled {
			opacity: 1;
		}

	}
	
	@media (max-width: 800px) {
		
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 3rem 3rem 0 3rem;
		
		&__left {
			padding: 0;
		}

		&__right {
			display: flex;
			align-items: flex-end;
		}

		> div {
			
			position: relative;
			width: 50%;
			
		}
		
		img {
			
			bottom: 0;
			position: absolute;
		
		}

		#lottie-envelope-animation--hp {
			bottom: -40px;
		}
		
	}
	
	@media (max-width: 600px) {
	
		flex-direction: column;
		
		> div {

			width: 100%;
			
			&:last-of-type {
			
				text-align: center;
			
			}
			
		}
		
		img {
		
			position: static;
		
		}

		#lottie-envelope-animation--hp {
			margin-top: -14rem;
			bottom: -70px;
		}
	
	}

	@media (max-width: 480px) {

		#lottie-envelope-animation--hp {
			margin-top: -8rem;
			bottom: -40px;
		}

	}
	
}