.internal-dropdown-nav {
		
	margin-top: -4.5rem;
	position: relative;
	
	@media (max-width: 770px) {
		
		margin-top: 0;
		max-width: 100%;
		
		.row:before {
			
			background: #0b2d49;
			display: block;	
			content: "";
			height: 50%;
			position: absolute;
			top: 0;
			width: 100%;

		}
		
		/* Take a look inside DCBL */
		
		& + .block.bg-light {

			padding-top: 12rem;
			
		}
		
	}

	.dropdown-nav__inner {
		
		align-items: center;
		background: #31BEAF;
		border-radius: 1rem; 
		display: flex;
		padding: 2rem;
				
		h3 {

			width: 25%;


			@media (max-width: 1024px) {
				width: 24%;
				font-size: 1.5rem;
				line-height: 2.7rem;
			}

			@media (max-width: 770px) {
				font-size: 2rem;
				line-height: 4.2rem;
			}

		}
				
		& > * {
			
			width: 20%;
			
		}
		
		@media (max-width: 1024px) {
			margin-left: inherit;
		}

		@media (max-width: 770px) {
		
			flex-direction: column;
			padding: 3rem 1rem 8rem 1rem;
			
			& > * {
			
				margin: 0 0 2rem 0;
				text-align: center;
				width: 100% !important;
				
			}

		}	

		
	}
	
	&__dropdown {
		 
		display: flex;
		margin-left: 5%;
		width: 50% !important;
		
		@media (max-width: 1024px) {
			margin-left: 0;
			width: 52% !important;
		}

		@media (max-width: 770px) {
		
			flex-direction: column;
			
		}
		
		select {
			
			border: none;
			border-radius: 0;
			height: auto;
			max-width: 30rem;
			outline: none;
			font-size: 1.4rem;

			@media (max-width: 1024px) {
				max-width: 31rem;
			}
			@media (max-width: 860px) {
				font-size: 1.2rem;
			}

			@media (max-width: 760px) {
				max-width: 21rem;
			}

			option {
				@media (max-width: 1024px) {
					font-size: 1rem;
					line-height: 0.7rem;
				}
			}
			
			@media (max-width: 770px) {
				font-size: 1.2rem;
				margin-bottom: 2rem;
				max-width: 100%;
				padding-bottom: 1rem;
				padding-top: 1rem;
			
			}
			
		}
		
		.btn {
		
			margin-left: -.3rem;
			width: auto;
			
			@media (max-width: 1024px) {
				font-size: 0.9rem;
				line-height: 1.8rem;
			}

			@media (max-width: 770px) {
				font-size: 1.4rem;
				line-height: 2.4rem;
				margin: 0 auto;
				max-width: 40rem;
				padding: .9rem 5rem .7rem 1.3rem;
				
			}
			
		}
		
	}
	
	&__img {
		
		background: white;
		border-radius: .5rem; 
		box-shadow: 0px .5rem 1rem #777;
		max-width: 27rem;
		padding: 2rem;
		position: absolute;
		right: 4rem;
		z-index: 1;
		
		@media (max-width: 770px) {
		
			bottom: -35%;
			right: auto;
		
		}
		
		img {
			
			width: 100%;
			
		}
		
	}
	
}