//******************************************************************************
// BIG CTA
//******************************************************************************

.big-cta {
    position: relative;

    &__chevron {
        position: absolute;
        right: 0;
        top: 0;
        width: 45%;
        height: 100%;
        object-fit: cover;
        object-position: left;
    }
}