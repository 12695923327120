//******************************************************************************
// BASE
// html, body & default styles
//******************************************************************************
html {
    font-size: 62.5%;
}

body {
    color: #0C304E;
    font-size: 1.6rem;
    font-family: $font-family;
    font-weight: 400;
}

p {
    line-height: 1.5;
}

strong,
b {
    font-weight: 600;
}

img {
    height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p {
    margin-top: 2rem;
}

a {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.btn {
    background-color: var(--primary);
    padding: 9px 13px 7px;
    text-decoration: none;
    border: none;
    border-radius: var(--border-radius, 5px);
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    color: white;

    + .btn,
    + button {
        margin-left: 2rem;
    }
}

.btn--teal {
    background: $teal;
    color: $blue;
}

.link {
    display: inline-block;
    appearance: none;
    padding: 0;
    background: transparent;
    border: none;
    text-decoration: underline;
    font-size: 1.4rem;
}


// FLEX
// -----------------------------------------------------------------------------
.row-reverse {
    @include media-breakpoint-down(xs) {
        display: flex;
        flex-direction: column-reverse;
    }
	& > div:first-of-type {
		padding-bottom: 2rem;
		padding-top: 2rem;
        @media screen and (min-width: 768px){
            padding-top: 0;
            padding-bottom: 0;
        }
	}
}
.flex-end {
    display: flex;
    justify-content: flex-end;
}

// FOCUS-VISIBLE
// -----------------------------------------------------------------------------
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}
.js-focus-visible .focus-visible {
    outline: 1px dashed var(--primary);
}

html[data-useragent*="rv:11.0"] {
    
}

// OBJECTFIT
// -----------------------------------------------------------------------------
div,
header {
    &.objectfit {
        height: 100%;
        img {
            -o-object-fit: cover;
            object-fit: cover;
            height: 600px;
            width: 100%;

            @include media-breakpoint-down(md) {
                height: 400px;
            }            
        }
    }
}
.compat-object-fit {
    background-size: cover;
    background-position: center center;
    img {
        opacity: 0 !important;
    }
}

svg.icon {
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    fill: currentColor;
    position: relative;
    top: 3px;
    margin: -2px 5px 0 0;
}

.skiplink {
    position: absolute;
    left: -9999em;
    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        font-size: 2rem;
        z-index: 1000;
        background: #000000;
        padding: 1rem 1.8rem;
        border: 4px solid white;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.alignright { float: right; }
.alignleft { float: left; }
.aligncenter { display: block; margin-left: auto; margin-right: auto; }

.direct-security-toggle {
    .hero.bg-blue {
        background-color: #1a507a;
    }
}
