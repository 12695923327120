.page-template-template-form {
	
	h3 {
	
		color: #0c304e
	
	}
	
	.frm_page_bar {
		
		float: none !important;
		margin: 0 auto !important;
		padding-bottom: 2rem;
		max-width: 117rem;
		
	}
	
	.frm_rootline.frm_show_lines {
	
		&:before {
			
			border-color: #4A667D !important;
			left: auto;
			position: absolute;
			right: 9%;
			margin: 0;
			width: 82% !important;
			z-index: -1;
			
		}	

		&:after {

		    background: linear-gradient(to right,#2b80ae,#e3c335);
		    bottom: 0;
		    content: '';
		    height: 0.2rem;
		    left: 0;
		    margin: 0 auto;
		    position: absolute;
		    right: 12%;
		    top: 1.5rem;
		    width: 82%!important;
		    z-index: -2;
		    left: 12%;
		    margin: 0;
		    width: 76%!important;
			
		}
	
	}

	&.page-id-1413 {

		.hero.hero--plain {

			.container {
			    padding-bottom: 4rem;
			    padding-top: 4rem;
			}

			.row {
				align-items: center;
			}

			.alpha {
				margin-bottom: 0;
			}

		}

	}

	&.page-id-1424 {
		.frm_rootline.frm_show_lines {
	
			&:before {
			    border-color: #4a667d!important;
			    left: 12%;
			    position: absolute;
			    right: 5%;
			    margin: 0;
			    width: 76%!important;
			    z-index: -1;
			}

			&:after {

			}

		}
	}

	
	input[type="button"],
	input[type="button"]:disabled {
		
	  	
		text-indent: 1000px;
		
	}
	
	.frm_page_num_2 .frm_rootline.frm_show_lines:before {
		
		width: 66% !important;	

	}
	
	.frm_page_num_3 .frm_rootline.frm_show_lines:before {
		
		width: 50% !important;	
		
	}
	
	.frm_page_num_4 .frm_rootline.frm_show_lines:before {
		
		width: 32% !important;	
		
	}
	
	.frm_page_num_5 .frm_rootline.frm_show_lines:before {
		
		width: 16% !important;	
		
	}
	
	.frm_page_num_6 .frm_rootline.frm_show_lines:before {
		
		width: 0 !important;	
		
	}

	.frm_rootline_single {
		position: relative;

		&:not(:first-of-type){
			&::before{
					content: "";
					height: 2px;
					width: 100%;
					position: absolute;
					top: 1.1rem;
					left: -50%;
					background-color: white;
					z-index: 0;
				}

			}

	
		input[type="button"] {

			background: #ffffff!important;
			background-size: 100% 100%;
			border: none;
			height: 2rem !important;
			position: relative;
			top: 0;
			width: 2rem !important;
			padding: 0;
			font-size: 1.4rem;
			z-index: 1;

			// }

		}

		
		span {
			
			color: #fff;
			padding-top: 1rem;
			display: block;
			
		}
		.frm_rootline_title{

		}
		
	}
	
	.frm_rootline_single.frm_current_page ~ .frm_rootline_single {
		&::before{
			background-color: #4a667d;
		}
	
		input[type="button"] {

			background: #4A667D !important;

		}
	
		span {
			
			color: #4A667D;

		}
	
	}
	
	.frm_fields_container {
		
		background: #ffffff;
		border-radius: 1rem;
		margin-bottom: 4rem;
		padding: 3rem;

		@media screen and (max-width: 1200px) {
			border-radius: 0px;
			max-width: 100%;
		}

	}	

	#frm_field_22_container {

		max-width: 80%;
		
	}
	
	
	.with_frm_style .frm_required {
		
		margin-left: -.3rem;
		
	}
	
}

	.frm_style_formidable-style.with_frm_style .frm_submit {
		
		button {

			background: #0b2d49 url(../../assets/images/arrow-right-white.svg) no-repeat calc(100% - 1rem) 50%;
			border: none;
			color: #fff;
			padding: 1rem 4rem 1rem 1rem;

			
			&:hover {

				background: #04111c url(../../assets/images/arrow-right-white.svg) no-repeat calc(100% - 1rem) 50%;

			}

		}
		
	}
	button.frm_prev_page{
		padding: 1rem 1rem 1rem 4rem!important;
		position: relative;
		background: #0b2d49!important;
		&::before{
			content: "";
			position: absolute;
			width: 8px;
			height: 12px;
			background: url(../../assets/images/arrow-left-white-new.svg) no-repeat;
			left: 1rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	
	.with_frm_style .frm_required {
		
		margin-left: -.3rem;
		
	}
	


/* For the time being this is just for the form on the Location - North West page */

.frm_forms.with_frm_style  {

	input {
	
		font-size: 1.4rem;
		padding: 2rem 1rem;
		
		&:not([type="radio"]):not([type="checkbox"]).valid-input {
		
			background: #fff url(../../assets/images/green-tick.svg) no-repeat calc(100% - 1rem) center;
		  
		}
		
	}

}

.frm_top_container{
	&.vertical_radio{
		label{
			font-size: 1.5rem;
			&:first-child{
				margin-bottom: 2rem;
			}
		}
	}
}