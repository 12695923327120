.page-template-template-letter-cycle-recovery {

	.hero--letter-cycling {
		padding: 7.5rem 0 10rem 0;
		overflow: visible;
		margin-bottom: 6rem;

		.container {
			position: relative;
			z-index: 2;
		}

		.hero__chevron-large {

			// width: 55%;
			// width: unset;

			height: unset;
		    -o-object-position: left;
		    object-position: left;
		    position: absolute;
		    top: 0;
		    z-index: 1;
		}

		// up to 1350

		// @media screen and (max-width: 1540px) {

		// 	margin-bottom: 0;
		// 	padding-bottom: 6rem;

		// }

		// @media screen and (max-width: 767px) {
		@media screen and (max-width: 1350px) and (min-width: 767px) {

			padding: 4rem 0 1rem;

		}

	}	

	.hero__letter-animation-container {

		// @media screen and (max-width: 767px) {
		@media screen and (max-width: 1350px) and (min-width: 601px) {
			height: 360px;
		}

		@media only screen and (max-width: 767px) and (min-width: 370px) {
    		height: 200px;
    	}

		// @media screen and (max-width: 800px) and (min-width: 767px) {

		// 	margin-left: 4%;

		// }

		// @media screen and (max-width: 600px) {
		// 	height: 400px;
		// }

		// @media screen and (max-width: 575px) {
		// 	height: 310px;
		// }

		// @media screen and (max-width: 450px) {
		// 	height: 270px;
		// }

	}

	.hero__letter-animation {
	    margin-top: 11rem;
	    position: absolute;
	    width: 100%;
	    height: 100%;
		opacity: 0;
		animation: fadeInLetterAnimation ease 0.8s;
	    animation-iteration-count: 1;
	    animation-fill-mode: forwards;
	    animation-delay: 0.7s;

		// @media screen and (max-width: 767px) {
		@media screen and (max-width: 1350px) {
		    margin-top: 0;
		    top: 0;
		}

	    &-unopened {
	    	display: block;
	    	margin: 0;

	    	img {
	    		display: block;
	    		width: 100%;
	    		max-width: 420px;
	    		margin: 0 auto;
	    	}
	    }

	    #lottie-envelope-animation--hero {
	    	display: none;
	    }


	    &.lottie-active {

	    	.hero__letter-animation-unopened {
		    	position: absolute;
		    	left: 4rem;
		    	bottom: -14rem;

		    	img {
		    		max-width: 360px;
		    		box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.5);
		    	}



		    	@media screen and (max-width: 1400px) {
				    left: 2rem;
				    top: 92%;
				    -webkit-transform: translateY(-50%);
				    transform: translateY(-50%);
				    bottom: auto;
				    width: 80%;
		    	}

		    	@media only screen and (max-width: 1350px) and (min-width: 768px) {
				    top: 60%;
				    width: 300px;
				    left: 0;
				    right: auto;
				    img {
				    	margin: 0;
				    }
		    	}

		    	@media only screen and (max-width: 767px) and (min-width: 370px) {
		    		top: 90%;
				    width: 250px;
				    left: 15px;
				    right: auto;
				    img {
				    	margin: 0;
				    }
		    	}


		    	// @media only screen and (max-width: 1540px) and (min-width: 1281px) {
		    	// 	bottom: -4rem;
		    	// }

		    	// @media only screen and (max-width: 1280px) and (min-width: 1025px) {
		    	// 	width:  80% !important;
				   //  bottom: -6rem;
		    	// }

		  //   	@media screen and (max-width: 767px) {
				// // @media screen and (max-width: 1350px) {
		  //   		// display: none;
	   //  		    top: 57%;
				//     width: 45%;
				//     left: 10%;
		  //   	}

		  //   	@media screen and (max-width: 450px) {
		  //   		// display: none;
				// 	top: 57%;
				//     width: 45%;
				//     left: 2rem;
		  //   	}

		    }

		    #lottie-envelope-animation--hero {
		    	display: block;
		    	position: absolute;
		    	top: -40%;
		    	right: -4%; 
		    	width: 100% !important;
		    	max-width: 500px;
		    	height: auto !important;
		    	margin: 0 0 0 auto;

		    	@media screen and (max-width: 1400px) {
				    top: -21%;
				    right: -20%;
		    	}

		    	@media only screen and (max-width: 1350px) and (min-width: 768px) {
				    top: -16%;
					right: auto;
					width: 360px!important;
					left: 200px;
					img {
				    	margin: 0;
				    }
		    	}

		    	@media only screen and (max-width: 767px) and (min-width: 451px) {
		    		top: -16%;
					right: auto;
					width: 300px!important;
					left: 150px;
					img {
				    	margin: 0;
				    }
		    	}

		    	@media only screen and (max-width: 450px) and (min-width: 370px) {
		    		top: -16%;
					right: 15px;
					width: 280px!important;
					left: auto;
					img {
				    	margin: 0;
				    }
		    	}


		    	// @media only screen and (max-width: 1540px) and (min-width: 1281px) {
		    	// 	right: -24%; 
		    	// }

		    	// @media only screen and (max-width: 1280px) and (min-width: 1025px) {
		    	// 	width:  80% !important;
		    	// 	right: -24%;
				   //  top: -100px;
		    	// }

		    	// @media screen and (max-width: 800px) {
				   //  top: 16%;
				   //  right: -20%;
		    	// }

		   //  	@media screen and (max-width: 767px) {
		   //  		// display: none;

					// top: -16%;
				 //    right: 1%;
				 //    width: 50%!important;
		   //  	}

		   //  	@media screen and (max-width: 450px) {
		   //  		// display: none;

					// top: -16%;
				 //    right: 1%;
				 //    width: 60%!important;
		   //  	}
		    }

	    }

	}

	@keyframes fadeInLetterAnimation {
	    0% {
	        opacity: 0;
	    }
	    100% {
	        opacity: 1;
	     }
	}
	

	
	.chevron-divider {
		
		position: relative;
		width: 100%;
				
		&:after {
	
			border-top: .1rem solid #31beaf;
			content: '';
			display: block;
			height: 1.2rem;
			position: absolute;
			top: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			width: 100%;
			
		}
		
		h2 {

			background: white;
			display: inline-block;
			padding: 0 3rem;
			position: relative;
			z-index: 1;
			
			@media (max-width: 768px) {
			
				margin-bottom: 2rem;
				padding: 0 2rem;
				width: 70%;
			
			}
		
		}
	
	}	
	
	.logo-bar {

		border-radius: 1rem;
		box-shadow: 0 0 1rem #ccc;
		padding: 4rem 6rem 2rem 6rem;
		
		@media (max-width: 768px) {
		
			padding: 4rem 0 2rem 0;
		
		}
	
		&__logos {

			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			
			@media (max-width: 768px) {
			
				width: auto;
				flex-direction: column;
			
			}
			
		}

		&__logo {

			margin-bottom: 4rem;
			object-fit: cover;
		    max-width: 100%;
		    height: auto;
			
			@media (max-width: 768px) {
				margin-bottom: 4.5rem;
				width: auto;
	
			}

		}

	}
	
	.text-image {
		
		padding-bottom: 4rem;
		padding-top: 10rem;
		
		@media (max-width: 768px) {
	
			padding-bottom: 3rem;
			padding-top: 4rem;

		}
		
		&.bg-light {
		
			padding-bottom: 10rem;
			padding-top: 3rem;
			
		}
		
		h4 {
			
			margin-top: 4rem;
				
			&:before {

				content: url(../../assets/images/chevron-blue-small.svg);
				margin-right: 1rem;
				position: relative;			

			}
			
		}

		.image-caption {
			
			margin-top: 1rem;
			
		}
		
		.accreditations {
			
			padding-top: 4rem;
			
			p {
				
				margin-bottom: 2rem;
				padding-top: 2rem;
				
			}
			
			img {
				
				margin: 0 2%;
				width: 15%;
				object-fit: contain;
				max-height: 60px;
				
			}
		
		}
		
	}
				
	.block-heading + p {
		
		margin: 0 auto 6rem auto;
		max-width: 70rem;
		width: 100%;
		
	}
		
	.block.bg-light {

		.btn {
		
			margin-top: 3rem;
			
		}
	
	}
		
}