//******************************************************************************
// ARTICLES
//******************************************************************************

.searchandfilter {
	[data-sf-field-input-type="checkbox"] {
		ul li {
			padding: 0.8rem 0;
		}
	}

	ul li {
		padding: 1.5rem 0;

		&:first-of-type {
			padding-top: 0;
		}

		&:last-of-type {
			padding-bottom: 0;
		}
	}
}