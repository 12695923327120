.page-template-template-testimonials {
	
	h2 {
	
		font-size: 5rem;
	
	}
	
	.posts-and-filters {
	
		margin-bottom: -3rem;
				
		ul {
			
			margin-bottom: 6rem;
			padding: 0;
			text-align: left;
			
		}
		
		.sf-field-taxonomy-services {
			
			h4 {
				
				display: none;
				
			}

			label {
				@media (max-width: 1000px) {
					display: block;
				}
			}
		
			select {

				width: 50rem;

				@media (max-width: 1000px) {

					width: 100%;

				}	
			}
			
		}
		
		&__posts {
		
			margin-top: -3rem;
		
		}
		
	}

	#testimonial-posts-area {
	
		& > div {
		
			margin-top: 3rem;
		
		}
		
		.accreditation-post {
			
			border-radius: .5rem;
			height: 100%;
			left: 50%;
			padding: 3rem 3rem 11rem 3rem;

			&__top {
				
				min-height: 10rem;
				
			}
			
			&__logo {
			
				max-width: 100%;
			
			}
						
			.btn {
				
				bottom: 3rem;
				position: absolute;	
			 	transform: translate(-50%, 0);
				width: 60%;
				
			}
			
		}
		
	}
	
	.big-cta {
	
		margin-bottom: 6rem;
	
	}

	.trustpilot-grid {

		&__image {
			display: table;
			width: 360px;
			max-width: 90%;
			height: auto;
			margin: 0 auto 3rem;

			@media (max-width: 768px) {
				margin-top: 1rem;
			}
		}

	}
	
}