//******************************************************************************
// NAVIGATION
// All kind of navigation and menus, including breadcrumb.
//******************************************************************************
.main-nav {
    @include media-breakpoint-between(xs, md) {
        background: #fff;
    }
    .navbar {
        width: 100%;
        min-height: 3.2rem;
        .navbar--primary {
            height: 100%;

            @media (max-width: 1440px) {
                margin-right: 1rem;
            }

            @media (max-width: 768px) {
                margin-right: 0rem;
            }

            // MAIN NAVIGATION
            .menu-item {
                position: relative;

                // MAIN LINK
                .nav-link {
                    padding: 1rem;
                    font-weight: 600;
                    color: inherit;
                    @include fluid-type(1.3rem, 1.7rem);
                    @include fade-transition(0.3s);
                    &:hover {
                        &:before {
                            background: #000;
                        }
                        &:after {
                            color: inherit;
                        }
                    }
                    &:after {
                        display: none;
                    }
                }

                // SUB NAVIGATION TOGGLE
                .menu-dropdown-toggle {
                    display: none;
                }

                // SUB NAVIGATION
                .dropdown-menu {
                    padding: 0;
                    border: 0;
                    border-radius: 0;
                    margin-top: 0;
                    background: #fff;
                    // SUB LINK
                    .dropdown-item {
                        position: relative;
                        display: block;
                        width: 100%;
                        padding: 0.5rem 3rem 0.5rem 1rem;
                        font-weight: 400;
                        color: #000;
                        white-space: nowrap;
                        border: 0;
                        @include fluid-type(1.3rem, 1.8rem);
                        @include fade-transition(0.3s);
                        &:hover {
                            color: #000;
                            background: transparent;
                            &:after {
                                color: #000;
                            }
                        }
                        &:after {
                            display: block;
                            color: #000;
                            content: "\f105";
                            font: normal normal normal 1.8rem/1 FontAwesome;
                            position: absolute;
                            top: 1rem;
                            right: 1rem;
                            font-weight: 700;
                        }
                    }
                }

                // DESKTOP
                @include media-breakpoint-up(lg) {
                    
                }

                // TABLET
                @include media-breakpoint-only(lg) {
                    // MAIN LINK
                    .nav-link {
                        padding: 0.6rem;
                    }
                    // SUB NAVIGATION
                    .dropdown-menu {
                        // SUB LINK
                        .dropdown-item {
                            padding: 0.5rem 0.6rem;
                        }
                    }
                }

                // MOBILE
                @include media-breakpoint-between(xs, md) {
                    &:hover {
                        .nav-link {
                            color: #000;
                        }
                        .menu-dropdown-toggle {
                            .menu-dropdown-toggle-icon {
                                &:before,
                                &:after {
                                    background: #000;
                                }
                            }
                        }
                    }

                    // MAIN LINK
                    .nav-link {
                        padding: 0.2rem 0;
                    }

                    // SUB NAVIGATION
                    .dropdown-menu {
                        &.open {
                            position: relative;
                            display: block;
                            padding: 0;
                            background: transparent;
                        }
                        // SUB LINK
                        .dropdown-item {
                            padding: 0.5rem 0;
                            &:after {
                                right: 0;
                            }
                        }
                    }

                    // SUB NAVIGATION TOGGLE
                    .menu-dropdown-toggle {
                        padding: 0;
                        border: 0;
                        border-radius: 0;
                        position: absolute;
                        top: .75rem;
                        right: 0;
                        display: block;
                        background: transparent;
                        .menu-dropdown-toggle-icon {
                            position: relative;
                            cursor: pointer;
                            height: 1rem;
                            width: 1rem;
                            display: block;
                            &:before,
                            &:after {
                                background: #000;
                                content: '';
                                display: block;
                                width: 0.8rem;
                                height: 0.20rem;
                                top: 0.35rem;
                                position: absolute;
                                @include fade-transition(0.3s);
                            }
                            &:before {
                                -webkit-transform: rotate(-180deg);
                                transform: rotate(-180deg);
                            }
                            &:after {
                                -webkit-transform: rotate(90deg);
                                transform: rotate(90deg);
                            }
                        }
                        
                        &.open {
                            .menu-dropdown-toggle-icon {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// MAIN NAVIGATION TOGGLE
.navbar-toggler {
    display: none;
    @include media-breakpoint-between(xs, md) {
        padding: 0;
        border: 0;
        border-radius: 0;
        position: absolute;
        top: 2.4rem;
        right: 1.5rem;
        height: 3rem;
        display: block;
        .navbar-toggler-icon {
            position: relative;
            display: inline-block;
            width: 2.5rem;
            height: 0.25rem;
            background: #000;
            transition: all .2s ease-in-out;
            &:before,
            &:after {
                background: #000;
                content: '';
                display: block;
                width: 2.5rem;
                height: 0.25rem;
                position: absolute;
                transition: all .2s ease-in-out;
            }
            &:before {
                top: -0.7rem;
            }
            &:after {
                top: 0.7rem; 
            }
        }
        &[aria-expanded="true"]{
            .navbar-toggler-icon {
                background: transparent;
                &:before,
                &:after {
                    top: 0;
                    height: 0.20rem;
                }
                &:before {    
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                &:after {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }  
        }

        &:hover {
            background: transparent;
        }
    }
}

.menu-item-3045 {
    display: none;
}

.page-id-1335,
.page-id-1413 {
    .menu-item-110,
    .menu-item-111,
    .menu-item-2350,
    .menu-item-112,
    .menu-item-2606,
    .menu-item-2613,
    .menu-item-2614,
    .menu-item-2615,
    .menu-item-2616,
    .menu-item-743,
    .menu-item-27764 {
        display: none;
    }
        
    .site-header .dropdown-menu .nav-megamenu__menu  {
        .menu-item-3045 {
            display: list-item;

            &:after,
            &:hover:after {
                content: '';
            }
        }
    }

}