//******************************************************************************
// TYPOGRAPHY
//******************************************************************************

h1,h2,h3,h4,h5,h6, .alpha, .beta, .gamma, .delta, .epsilon, .zeta {
	font-family: $heading;
	font-weight: 700;
	margin-bottom: 0;
}

h1, .alpha {
	@include fluid-type(3.1rem, 3.1rem);
	margin-bottom: 45px;
	line-height: 1;
}

h2, .beta {
	@include fluid-type(2.9rem, 2.9rem);
	line-height: 1.2;
}

h3, .gamma {
	@include fluid-type(2.3rem, 2.3rem);
	line-height: 1.2;
}
.gamma-form-override{
	font-size: 2.3rem!important;
	font-weight: 700!important;
	font-family: $heading!important;
	color: #0c304e!important;
	line-height: 1.2!important;
	margin-bottom: 2rem!important;
}

h4, .delta {
	@include fluid-type(2rem, 2rem);
	line-height: 1.2;
}

h5, .epsilon {
	@include fluid-type(1.8rem, 2.8rem);
	line-height: 1.2;
}

h6, .zeta {
}

.wysiwyg--h2,
.wysiwyg--h3,
.wysiwyg--h4 {
	font-family: "Source Sans Pro",sans-serif;
}
	
.wysiwyg--h2 {
	font-size: 29px;
	font-weight: 700;
	color: #0C304E;	
}

.wysiwyg--h3 {
	font-size: 23px;
	font-weight: 700;
	color: #0C304E;	
}

.wysiwyg--h4 {
	font-size: 19px;
	font-weight: 700;
	color: #232323;
}

s {
	position: relative;
	text-decoration: none;
	
	&:after {
		content: "";
		position: absolute;
		bottom: 35%;
	    left: -1px;
	    height: 0.12em;
	    width: calc(100% + 2px);
	    background: currentColor;
	    display: block;
	}
}

.block-header {
	@include fluid-type(2.9rem, 5rem);
	font-family: $heading;
}