//******************************************************************************
// HERO
//**
.hero {
	position: relative;
	overflow: hidden;

	&__side-slider,
	&__side-image {
		position: absolute !important;
		width: 50%;
		height: 100%;
		right: 0;
		top: 0;
		object-fit: cover;

		@media (max-width: 667px) {
			position: relative !important;
			width: 100%;
		}

	}

	&__side-image img {

		@media (max-width: 850px) {
			height: auto;
		}

	}

	&--side-slider {
		padding: 7.5rem 0 10rem 0;
		+ div {
		    padding-top: 6rem;
		}
		@media (max-width: 667px) {
			padding: 7.5rem 0 0 0;
		}
	}

	&__side-image {
		@media (max-width: 1024px) {
		    width: 65%;
		}
	}
}