/* Overview + Locations */

.inline-notification {
	margin-top: 5rem;
	position: relative;
	
	.inline-notification__inner {

		border-radius: .5rem;
		// padding: 2rem;
		padding: 2rem 28rem 2rem 2rem;
		
		@media (max-width: 768px) {
			
			margin-bottom: 3rem;
			padding: 2rem 2rem 8rem 2rem;	
			
		}

	}
	
	p {
		
		margin: 0;
		line-height: 1.5;
		// width: 60%;
		
		// @media (max-width: 1024px) {
		// 	width: 45%;
		// }

		@media (max-width: 768px) {
			
			margin: 0 auto;
			text-align: center;
			width: 70%;	
			
		}
		
	}
	
	img {
		
		border-radius: .5rem;
		box-shadow: 0 .5rem 1rem #777;
		max-width: 50%;
		position: absolute;
		right: 2rem;
		top: 50%;
		transform: translateY(-50%);

		// @media only screen and (max-width: 1820px) and (min-width: 1350px) {
		// }
		
		@media (max-width: 768px) {
			
			left: 50%;
			top: 80%;
			transform: translate(-50%, -30%);
			
		}
	
	}
	
}