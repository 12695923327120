/* Debt Recovery / About Us / Can't Pay We'll Take It Away / Working At DCBL / Locations */

.hero--no-image {
	
	padding: 7.5rem 0 10rem 0;
	
	@media (max-width: 768px) {
	
		padding: 3rem 1.5rem;
	
	}
	
	& + div {
	
		padding-top: 3rem;
		
		@media (min-width: 768px) {
		
			padding-top: 6rem;
			
		}
	
	}
	
	/*.col-md-1,
	.col-md-4 {
		
		position: relative;
		z-index: 2;
		
	}
	
	.hero__chevron-large {
		
		left: 13rem;
		width: 50%;

	}*/

}