.cards {
		
	.card {
		
		@media (max-width: 768px) {
			
			margin-bottom: 4rem;
			
		}	
		
	}
	
	/* Debt Recovery Cards */

	.card__hover-text {
		line-height: 1.5;
	}
		
	.card__frontside,
	.card__backside {
	
		border-radius: 1rem !important;
		padding: 2rem 3rem;
		
		.card__icon:not(.card__icon--image) {
			
			padding-top: 2rem;
		
		}
		
		h4 {
			margin-top: 10px;
			color: #31beaf;
			font-family: Montserrat,sans-serif !important;
			
		}
		
	}
	
	.card__frontside {
		
		img {
		
			margin-bottom: 3rem;
		
		}
	
	}
	
	/* Home Page */
	
	@media (max-width: 768px) {
	
		.col-md-4 {

			margin-bottom: 4rem;
			
			&:last-of-type {
				
				margin: 0;
				
			}

		}

		.col-md-6 {
			&:last-child,
			&:nth-last-child(2) {
				// margin-bottom: 0 !important;
			}
		}
		
	}

	@media (max-width: 600px) {
		.col-md-6 {

			margin-bottom: 4rem;
			
			&:last-of-type {
				
				margin: 0;
				
			}

		}
	}
	
	.card--stat {
		
		border-radius: 1rem !important;
		height: 100%;
		// min-height: 360px;
		
		@media (max-width: 1024px) {
			// padding-bottom: 4rem;
			min-height: auto;
		}

		@media (max-width: 768px) {
			height: unset;
			margin-bottom: 0;
		}
		
		.card__stat {
			
			color: #fff;
			font-family: "Source Sans Pro",sans-serif;
			font-size: 8rem; // font-size: 12.5rem; 
			font-weight: 900;
			line-height: 1;
			// margin-bottom: -3rem;
			
			sup {
			
				font-size: 4rem; // font-size: 8rem;
   				top: -0.8em;
				// left: -0.5rem;
				// top: -0.5em;
				
			}

			@media screen and (max-width: 1440px) and (min-width: 1366px) {
				font-size: 6rem;
				sup {
					font-size: 2rem;
				}
			}

			// @media screen and (max-width: 1365px) and (min-width: 1280px) {
			// 	font-size: 8.5rem;
			// 	sup {
			// 		font-size: 4rem;
			// 	}
			// }


			// @media (max-width: 1280px) {
			// 	// font-size: 7.5rem;

			// 	sup {
			// 		font-size: 5rem;
			// 	    left: 0.5rem;
			// 	    top: -0.5em;
			// 	}

			// 	margin-bottom: -1rem;
			// }


		}
		
		&.bg-teal .card__frontside {
			
			h4 {
			
				color: #0b2d49;
			
			}
		
		}
			
	}
	
	.card--flip {
	
		height: 100%;
	
		.flip-area {
		
			height: 100%;
		
		}
	
	}
	
	/*.card__heading { THIS BROKE DEBT-RECOVERY CARDS BUT WILL BE NEEDED SOMEWHERE ELSE

		margin-top: -2rem;
		
	}*/

	.extra-margin {
		margin-bottom: 6rem;
	}
	
}

.hover-flip-cards.block {
	@media (max-width: 450px) {
		padding-top: 1rem;
	}
}