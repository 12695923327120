/* Letter Cycle Recovery / Debt Recovery / Locations */

.block.bg-light {
	
	@media (max-width: 1350px) {
	
		.col-lg-2 {
		
			display: none;
			
		}
		
	}
	
	.color-teal {
		
		color: #31beaf;
		
	}
	
	.row.steps {
		
		margin-top: 0;
		padding-top: 0;

		
		@media (max-width: 768px) {
		
			flex-direction: column;	
			
			.step {
			
				// margin-bottom: 3rem;
				
				&:last-of-type {
				
					margin-bottom: 0;
					
				}
			
			}
			
		}
		
		.col.step {
		
			h4 {
				
				font-family: Montserrat,sans-serif;
				max-width: 12ch;
			
				@media (max-width: 768px) {
					
					margin-left: 2rem;
					max-width: unset;
				}
			
			}
			&>p{

				margin-top: 1rem;
				
			}
					
			&:before {
			
				content: url(../../assets/images/chevron-blue-small.svg);
				left: -2rem;
				margin-right: 1rem;
				top: 2.6rem;
				position: relative;
				
				@media (max-width: 768px) {
					
					left: 0;
				
				}
	
			}
		
		}
		
	}
		
}