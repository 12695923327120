div.internal-dropdown-nav__img img {
    width: 100%;
    height: auto;
}

div.container.internal-dropdown-nav {
    contain: layout style;
    z-index: 1;
}

a.btn.btn--red.site-header__menu {
    transition: all 0.3s ease;
}

 .home main#content div.bg-light:before {
    content: "";
    display: block;
    width: 100%;
    height: 280px;
}

// The major impact change
// .hero.hero--slider {
//   height: 71vh;
// }