/* Debt Recovery / About Us / Can't Pay We'll Take It Away / Working At DCBL / Locations */

.hero--side-image {

	padding: 7.5rem 0 10rem 0;

	p {
		max-width: 88%
	}

	.hero__side-image {
		clip-path: polygon(80% 0, 95% 50%, 80% 100%, 0% 100%, 13% 50%, 0% 0);
		
		@media (max-width: 850px) {
			clip-path: none;
		}
	}
	

	@media (max-width: 850px) {
		padding: 0;
	
		display: flex;
		flex-direction: column-reverse;
		
		.container {

			padding: 3rem 1.5rem;
			
		}
		
		img {
			
			position: static !important;
			width: 100%;
			height: auto;
			
		}
	
	}

	@media (max-width: 667px) {
		padding: 0rem 0 10rem 0;
		// padding-bottom: 36rem;
	}
	
	@media (max-width: 400px) {
		padding: 0rem 0 0rem 0;
		// padding-bottom: 30rem;
	}
	
	& + div {
	
		padding-top: 3rem;
		
		@media (min-width: 768px) {
		
			padding-top: 4rem;
			
		}
	
	}
	
	.col-md-1,
	.col-md-6 {
		
		position: relative;
		z-index: 2;

		@media (min-width: 769px) {
			&.extra-padding {
				// padding-right: 112px; ////////////////////////////////////
			}
		}
		
	}

	.hero__chevron-small {
		display: none;
	}
	
	.hero__chevron-large {

		// width: 55%;
		width: unset;
		right: 55%;

		&.aos-animate {
			width: unset;
			right: 45%;
		}
		
		@media only screen and (max-width: 1024px) and (min-width: 768px) {
			// width: 60%;
			width: unset;
			right: 40%;

			&.aos-animate {
				width: unset;
				right: 40%;
			}
		}

		@media (max-width: 850px) {
			display: none;
		}
		
		// left: 50%;
		// transform: translateX(-90%);
		// left: 13rem;
		// width: 50%;

		// @media (max-width: 1400px) {
		//     width: 100%;
		//     left: -45%;
		// }
		// @media (max-width: 850px) {
		// 	display: none;
		// }
	}

	> .container > .row > .col-md-6 {
		@media (max-width: 850px) {
			flex: 0 0 100%;
		    max-width: 100%;
		}
	}
}

.page-template-template-about-us {
	@media (max-width: 450px) {
		.hero--side-image {
			> .container {
				padding-bottom: 3rem;
			}
		}
	}
}