.solution-animation {
		
	// padding: 0 0 4rem 0;
	padding: 0;

	@media (max-width: 600px) {
		padding: 0 0 0 0;
	}
	
	.block-header {
	
		font-size: 5.8rem;
		line-height: 1;
		margin-right: 150px;

		@media (max-width: 1180px) { //1024px
			
			margin-right: 0;
			
		}
		
		@media (max-width: 767px) {
			
			font-size: 4.5rem;	
			
		}
	
	}
	
	.tick {
	
		background: #fff;
		border-radius: 100px;
		height: 25px;
		margin-bottom: 10px;
		margin-top: -10px;
		position: relative;
		width: 25px;
		z-index: 10;
		
		&:before {
		
			background: url(../../assets/images/green-tick.svg) no-repeat center center;
			background-size: 15px 15px;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		
		}

		
	}
	
	.inactive {
	
		& + .tick:before {
		
			background: none;
		
		}
		
	}
	
	.small {
		
		display: none;

		@media (max-width: 1180px) { //1024px

			display: block;
			padding: 6rem 0 0 0;

		}
		
	}
	
	.large {
		
		@media (max-width: 1180px) { //1024px

			display: none;

		}		
		
	}

	.btn {

		@media (min-width: 1180px) { //1024px
			
			position: absolute;
			right: 0;
			top: 2rem;
			
		}

	}
			
	.block-header.color-teal + h2  {
		
		font-family: Montserrat,sans-serif;
		margin-bottom: 2rem;
		
		@media (max-width: 767px) {
			
			font-size: 1.7rem;	
			
		}
		
	}
	
	.inner {
	
		@media (min-width: 768px) {
	
			padding-left: 30px;
			padding-right: 30px;
			
		}
		
	}

	.inactive {
		
		fill: #2a2b39 !important;
		filter: contrast(0%);
		filter: grayscale(100%);
		opacity: 0.1;
		
	}

	.grid-item-big:not(.item4) {

		border: none !important;

		position: relative;
		top: -86%;

		&:nth-child(-n+3) {

			top: 21%;

		}

	}
	
}	

// #animateHomeSection {
	
// 	padding: 14rem 0 0 0;

// 	@media (max-width: 1040px) {

// 		.large {
// 			margin-bottom: 9.5rem;
// 		}
// 	}

// 	@media (max-width: 1040px) {
// 		padding: 9.5rem 0 0 0;
// 	}

// }


// ANIMATED GRAPHIC SECTION - TEMPLATE SPECIFIC
body.home,
body.page-template-template-pagebuilder, //good
body.page-template-template-overview,
body.page-template-template-letter-cycle-recovery {
	#animateHomeSection {

		padding: 10rem 0 0 0;

		.large {
			p {
				line-height: 1.6
			}
		}

		@media only screen and (max-width: 1440px) and (min-width: 1351px) {
			padding: 5rem 0 0 0;
		}

		@media only screen and (max-width: 1350px) and (min-width: 1181px) {
			padding: 5rem 0 0 0;
		}

		@media (max-width: 1180px) { //1024px
			padding: 8rem 0;
		}

		@media (max-width: 450px) { 
			padding: 8rem 0 4rem 0;
		}

		
		// padding: 14rem 0 0 0;

		// @media (max-width: 1040px) {

		// 	.large {
		// 		margin-bottom: 9.5rem;
		// 	}
		// }

		// @media (max-width: 1040px) {
		// 	padding: 9.5rem 0 0 0;
		// }

	}
}

/* BIGGER VERSION SPECIFIC */

#big-grid-container {
  
	p {
		
		font-family: "Source Sans Pro",sans-serif;
		font-size: 1.9rem;
		font-weight: 700;		
		
	}
	
	.item4 {
		
		.icon {
			
			opacity: 0.1;
			
		}
		
		.icon-active {

			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;

		}	
		
	}
	
}

.grid-item-big .icon-active svg {
    display: block;
    stroke-dasharray: 2653;
    stroke-dashoffset: 2653;
}

.grid-container-big {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 18rem auto 18rem;

    /*background-color: black;*/
    grid-gap: .1rem;
    padding: .1rem;
    min-width: 0;
    min-height: 0
}

.grid-item-big {
    /*	border: .1rem solid rgba(0, 0, 0, 0.8);
    text-align: center;*/
    justify-content: center;
    /* overflow: hidden; */

    p {
		line-height: 1.3;
	}
}

/* place the extending svg in the middle row across four columns */
.grid-item-big.item4 {
    grid-column: 1 / 4;
	position: relative;
}

.grid-item-big .svgBackground {
    margin: 0;
    height: 80%;

}

/* SMALLER VERSION SPECIFIC */

#small-grid-container {
	
    // --setMobileSVGHeight: 70rem;
    // --setTabletSVGHeight: 100rem;
	
		.icon {
			
			height: 100%;
			left: 0;
			opacity: 0.1;
			position: absolute;
			
		}
		
		.icon-active {

			height: 100%;
			position: absolute;
			z-index: 1;

		}		
}

/* style for the long svg */
.grid-item-small .icon-active svg {
    display: block;
    stroke-dasharray: 1054;
    stroke-dashoffset: 1044;
    /*padding-left: 45%;*/
}

/* style for the icon svg */
.grid-item-small .svgBackground {
	background-position: 45% 50% !important;
    height: 95%;
    margin-right: 1.1rem;

}

body.home .grid-item-small .svgBackground.step5 {
	background-position: 43%  50% !important;
}

.grid-container-small {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 35% 20% 45%;
    grid-template-rows: auto auto auto auto auto auto;
    /*background-color: black;*/
    grid-gap: .1rem;
    padding: .1rem;
	/*margin-top: 6rem;*/
    min-width: 0;
    min-height: 0;
    grid-auto-flow: column;
    /*text-align: center;*/
    justify-content: center;
    @media (max-width: 1182px) {
    	&>.col1{
	    	.tick{
	    		top: 7rem;
	    		bottom: unset;
	    		margin: 0;
	    		@media (max-width: 800px) {
	    			top: 6rem;
	    		}

	    	}
	    	&+.col1{
	    		.tick{
	    			top: 50%;
	    			transform: translateY(-50%);
	    		}
	    	}
	    	&.index-6{
	    		.tick{
	    			top: unset;
	    			transform: none;
	    			bottom: 7rem;
	    		}
	    	}
	    }
	    &>.col2{
	    	margin-top: 7rem;
	    	margin-bottom: 7rem;
	    	@media (max-width: 800px) {
	    		margin-top: 6rem;
	    	}
	    }
    }
    

}

/* style for the each cell */
.grid-item-small {
	padding-bottom: 2rem;
    position: relative;
    min-height: 10rem;
	
	.tick {
		position: absolute;
		right: -2.1rem;
		top: 0;
	}


}

/* set this grid containing the line svg to take all 6 rows and make second colum*/
/* set the height here, this determines the grid size  */

.grid-item-small.item4 {
    grid-row: 1 / 7;
    grid-column: 2;
    height: 76.7rem;
	position: relative;
}

/* select the right column pad and align the text */
.grid-item-small.col3 {
  
    text-align: left;
    padding-left: 1.1rem;
    display: flex;
    align-items: center;

}


#topBlock {
    background-color: rosybrown;
}


/* 
@media screen and (max-width: 802px) {


}


/* tablet */
@media screen and (max-width: 767px) {
    #topBlock {
        background-color: rgb(214, 25, 142);
    }


    /* #path2 {
        stroke-width: 16;
    } */
    .grid-item-small.item4 {

        height: unset;
    }
}

/* tablet/mobile transition */
@media screen and (max-width: 767px) and (min-width: 601px) {

	.grid-item-small .tick {
		right: -1.9rem;
	}

}

/* mobile */
@media screen and (max-width: 600px) {
	.solution-animation{
		.inner{
			padding: 0;
		}
	}
    #topBlock {
        background-color: yellow;
    }

    .grid-item-small.item4 {

        height: unset;
    }
    .grid-container-small{
    	grid-template-columns: 40% 15% 45%;
    }


}