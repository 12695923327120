.big-cta {

	box-shadow: 0 0 0.5rem #999;
	border-radius: 1rem;
	overflow: hidden;
	padding: 5rem 6rem 8rem 6rem;
	margin-bottom: 4rem;
	
	@media (max-width: 768px) {
		
		padding: 3rem 3rem 4rem 3rem;	
		
	}
	
	.col-md-7 {
		
		z-index: 1;
		
	}

	&__header {

		font-family: "Source Sans Pro",sans-serif;
		font-size: 5rem;
		font-weight: 300;
		line-height: 1.2;
		
		@media (max-width: 768px) {
		
			font-size: 4rem;
		    line-height: 5rem;
		
		}

	}
	
	p {
	
		margin-bottom: 2rem;
		margin-top: 1rem;
		
	}
		
	@media (max-width: 768px) {	
	
		.row {

			max-width: calc(100% - 3rem);
		
		}
	
		// &__chevron {
		
		// 	height: auto;
		// 	object-fit: contain;
		// 	right: -2rem;
		// 	top: 6rem;
		// 	width: 12rem;
		// 	width: 50%;
		
		// }
	
	}
	
	@media (max-width: 600px) {
		&__chevron {
    		height: auto;
		    -o-object-fit: contain;
		    object-fit: contain;
		    /* right: -2rem; */
		    top: 4rem;
		    width: 12rem;
		    width: 28%;
	   	}

	   	&__header {
	   		max-width: 80%;
	   	}
	}

	@media (max-width: 400px) {	

		&__chevron {
		
		    width: 35%;
		
		}
	
	}
}