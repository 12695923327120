.btn {
	
	font-size: 1.4rem;
	font-weight: 600;
	padding: .9rem 3rem .7rem 1.3rem;
	position: relative;
		
	&:after {

		position: absolute;
		right: 1.3rem;
		top: 50%;
		transform: translateY(-40%);

	}

	@media screen and (min-width: 1025px) and (max-width: 1821px) {
		font-size: 1.2rem;
	}
}

.btn--half {

	margin-bottom: 2rem;
	width: calc(50% - 2rem);
	
	&:nth-child(even) {
		
		margin: 0;
		
	}	
	
	&:first-of-type {
		
		margin-bottom: 2rem;
		margin-top: 2rem;
		
	}
		
	@media (max-width: 1024px) {
		
		width: 100%;	
		
		& + .btn--half {
			
			margin-bottom: 2rem;
			margin-left: 0;
			
		}
		
	}

}

.btn--blue,
.btn--red {
	
	color: #fff !important;

	&:after {

		content: url(../../assets/images/button-arrow-right-white.svg);

	}
	
	&:hover {
		
		background: #04111C;
		
	}

	a {
		color: #fff !important;
		text-decoration: none;
	}
	
}

.btn--red {
	
	background: #CC412C;
	
	&:hover {
		
		background: #80291B;
		
	}
	
	@media (max-width: 768px) {

		font-size: 1.2rem;
		margin: 0 1.5rem 0 0;

	}
	
}
	
.btn--teal {
	
	color: #0b2d49 !important;

	&:after {

		content: url(../../assets/images/arrow-right-blue.svg);
		
	}
	
	&:hover {
		
		background: #7AD9CE;
		
	}
	
}

.btn--white {
	background-color: #ffffff;;
	color: #0b2d49;
	&:after {
		content: url(../../assets/images/arrow-right-blue.svg);
	}
	&:hover {
		color: #0b2d49;
	}
}

.reveal-content-trigger {
	
	margin-bottom: 1rem;
	
}

.hero .btn {

	margin-top: 1rem;

}