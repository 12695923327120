.page-template-template-about-us {
			
	/* Our People */
	
	.block.bg-light:not(.cards) {
		
		.row.steps {
			
			margin-top: 1.5rem;
			
		}
		
		.col-md-12,
		.col.step {
			
			padding-left: 15px !important; // overrides .no-left-pad
			
		}
		
		.col-lg-7 {
			
			margin-left: 2rem;
		}
		
		.col.step:before {
			
			display: none;
			
		}
		
		.btn--blue {
			background: #31beaf;
			color: #0b2d49 !important;
			margin: 2rem 0 0 0;
			
			&:after {
			
				content: url(../../assets/images/arrow-right-blue.svg);
			
			}
			
			&:hover {
				
				background: #7ad9ce;
				
			}
			
		}
		
	}
	
	.dcbl-stadium {

		margin: 6rem 0 -18rem 0;
		
		& + div {
		
			padding-top: 18rem;
			
			a {
			
				color: #31beaf;
			
			}
		
		}
	
	}
		
	/* Text Container */
	
	.text-container {
		
		h4 {
						
			&:before {
				
				content: url(../../assets/images/arrow-right-blue.svg);
				margin-right: 1rem;
				
			}
			
		}
		
	}
	
	/* Hover FLip Cards */
	
	.hover-flip-cards {
	
		h2 {

			font-size: 5rem;
			font-weight: 300;
			
			@media (max-width: 767px) {
			
				font-size: 2.3rem;
			
			}
			
			& + p {
				
				margin: 0 auto;
				max-width: 70rem;
				
			}

		}
		
		div[class^="col-md-6"], 
		div[class*="col-md-6"] {
		
			@media (max-width: 767px) {
			
				margin-bottom: 3rem;
				
				&:last-of-type {
				
					margin-bottom: 0;
					
				}
			
			}
		
		}
	
	}
	
	/* Accreditations */
	
	.hover-flip-cards + .bg-blue {
		
		padding-bottom: 8rem;
		padding-top: 8rem;
		
		@media (max-width: 767px) {
		
			padding-bottom: 3rem;
			padding-top: 3rem;
		
		}
		
		h2 + p {
		
			margin-bottom: 6rem;
			margin-top: 2rem;
		
		}
				
	}
	
	.accreditation-blocks > div {

		margin-bottom: 3rem;

	}
	
	.accreditation-block {
		
		border-radius: .5rem;
		height: 100%;
		margin-top: 6rem;
		
		& > div {

			background: #E6EAED;

		}
		
	}
	
	.accreditation-block__header {
		
		background: #fff;
		border-radius: .5rem;
		padding: 2rem;
		
		img {
			max-width: 100%;
		}
		
	}
	
	.accreditation-block__inner {
		
		padding: 2rem;
		
	}
	
	/* Video section overlap fix */

	main#content {
		#video {
			background-color: #e6eaed!important;
			padding-bottom: 0;

			> .container {
				@media (min-width: 768px) {
					margin-top: -34rem;
				}
			}
		}

		.text-image {
			@media (min-width: 768px) {
				padding-bottom: 32rem;
			}
		}
	}

	&.page-id-2131 { // TWO ABOUT US PAGES USING THIS TEMPLATE: /aboutussecurity/ AND /about-us/
		.bg--blue.hero, .bg-blue.hero{
			background: #1A507A;
		}
	}
}