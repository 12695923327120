//******************************************************************************
// FOOTER
//******************************************************************************
.site-footer {
}

.fpb-container {
	display: none;
}

 @media (max-width: 768px) {
		.fpb-container {
	width: 100%;
    position: sticky;
    display: flex;
    justify-content: end;
    bottom: 15px;
    right: 15px;
    padding: 0px 15px;
    z-index: 2;
	.floating-phone-button {
	    background-color: #31beaf;
	    font-family: "Open Sans", Sans-serif;
	    font-weight: 700;
	    border-radius: 100px;
	    height: 75px;
	    width: 75px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
			svg {
				max-width: 5rem;
				position: relative;
				fill: #fff;
			}
	}
}
	}