/* About Us / Working at DCBL */

.inline-image-slider.slick-slider {

	margin-left: .5rem;
	margin-right: .5rem;
	margin-top: 6rem;
	
	.slick-slide {

		margin: 0 1rem;

	}

	.slick-prev {

		left: -5rem;
		
		@media (max-width: 1350px) {
		
			left: 2rem;
		
		}

	}

	.slick-next {

		right: -5rem;
		
		@media (max-width: 1350px) {
		
			right: 2rem;
		
		}

	}

}