//******************************************************************************
// STRUCTURE
// General elements of the structure, including containers and wrappers
//******************************************************************************

// Content
// -----------------------------------------------------------------------------
.document {

}

.main {
    flex: 1 0 auto;
}

.content {
    position: relative;
}

.m-b-20 {
    margin-bottom: 2rem;
}
.m-b-60 {
    margin-bottom: 6rem!important;
}
.m-b-120 {
    margin-bottom: 12rem;
}
.m-t-60{
    margin-top: 6rem;
}
.p-t-60{
    margin-top: 6rem;
}
.m-l-auto {
    margin-left: auto;
}