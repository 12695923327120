.text-image {

	a {
		
		color: #31beaf;
		
	}
	
	.text-container + a {
	
		margin-top: 2rem;
	
	}

	div.objectfit img {
		// max-height: 381px;
	}

	@media(max-width: 1366px) {
		div.objectfit {
			margin-top: 3rem;
			display: block;
		}
	}
	
}