//******************************************************************************
// CARD
//**

.card--flip {
    position: relative;
    -webkit-perspective: 5000;
    -moz-perspective: 5000;
    perspective: 5000;
}

.card--flip:hover .card__backside,
.card--flip.hover .card__backside {
    transform: rotateY(0deg);
    border-radius: .25rem;
	
}

.card--flip:hover .card__frontside,
.card--flip.hover .card__frontside {
    transform: rotateY(180deg) translateZ(1px);
}

.flip-area {
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
}

.card__frontside {
    position: relative;
    transform: rotateY(0deg) translateZ(1px);
    z-index: 2;
    margin-bottom: 0;
}

.card__backside {
	height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(-180deg) translateZ(1px);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.card__frontside,
.card__backside {
    backface-visibility: hidden;
    transition: 1s;
    transform-style: preserve-3d;
}