.single-locations {
	
	.hero.hero--side-image {

		@media (max-width: 450px) {
			padding-bottom: 10rem;
		}

		@media (max-width: 400px) {
			padding-bottom: 0rem;
		}

	}

	#content h3:before {
		
		content: url(../../assets/images/chevron-blue-small.svg);
		height: 3rem;
		margin-right: 2rem;
		width: 3rem;
		
	}	
	
	.inline-notification {
	
		margin-top: 9rem;

		p {

			// @media (max-width: 1819px) {
			// 	width: 50%;
			// }

			// @media (max-width: 1539px) {
			// 	width: 45%;
			// }

			// @media (max-width: 1349px) {
			// 	width: 60%;
			// }

		}
		
		@media (max-width: 768px) {
		
			margin-bottom: 7rem;
			margin-top: 3rem;
		
		}
	
	}
	
	.form-container {
		
		margin-bottom: -6rem;
		
		@media (max-width: 1349px) {
		
			margin-bottom: 6rem;
		
		}
		
		.block-header {
			
			font-size: 4.5rem;
			margin-bottom: 2rem;
			
		}
	
	}
	
	.text-image {
	
		margin: 6rem 0;
		
		@media (max-width: 768px) {
		
			margin: 3rem 0 6rem 0;
		
		}
		
		div {
			
			@media (min-width: 768px) {
				padding-right: 0;
			}
			
			p {
			
				padding-right: 30px;
				
			}
			
			&:last-of-type {
				
				@media (min-width: 768px) {
					padding-left: 0;
					padding-right: 8px;
				}
				
			}
			
		}

		.row.row-reverse .col-md-12:first-of-type {

			@media (max-width: 1349px) {
				margin-bottom: 2rem;
			}

		}

		.row:not(.row-reverse) .col-md-12:last-of-type {

			@media (max-width: 1349px) {
				margin-top: 2rem;
			}

		}
		
		h3 {
		
			font-family: Montserrat,sans-serif;
			margin-top: 6rem;
		
		}

		h2.big-underline {

			@media (max-width: 1024px) {
				font-size: 2.9rem;
    			line-height: 3.8rem;
			}

		}
		
		.accreditation {
		
			margin-top: 3rem;

			p:first-of-type {
			
				padding-top: 3rem;
			
			}
		
		}
		
	}
		
	.block.bg-light .row.steps {

		border-top: none;
		margin-top: 0;
		padding-top: 0;
		
		.step:not(:first-of-type) {
			
			margin-top: 3rem;
	
		}
		
		h4 {
		
			font-family: Montserrat,sans-serif;
		
			&:before {
			
				content: url(../../assets/images/chevron-blue-small.svg);
				height: 3rem;
				margin-right: 1rem;
				width: 3rem;
				
			}
			
		}

	}
	
	.block.container {
		
		@media (max-width: 768px) {
			
			padding-top: 0;	
			
		}
	
		p {

			padding-left: 3rem;

		}
		
	}
	
}