.timed-modal {
		
	&__inner {
		
		border-radius: 1rem;
		max-width: 96%;
		
		@media (max-width: 768px) {
		
			padding: 0 2rem 2rem 2rem;
			
			.col-md-6:first-of-type {

				text-align: center;

			}			
			
		}
		
		img {
			
			margin-bottom: -1.6rem;
		
		}
		
		h3 {
			font-size: 3.4rem;
			margin-bottom: 2rem;
			margin-top: 4rem;
			
		}
		
		.btn {
			
			font-size: 1.4rem;
			margin-bottom: 2rem;
			width: 100%;
			
		}

		@media screen and (max-width: 768px) {

			.col-md-6 {
				display: flex;
				flex-wrap: wrap;
			}

			h3 {
				width: 100%;
				margin-top: 1rem;
				text-align: center;
				font-size: 32px;
			}

			.btn {
				width: 47%;
				margin-bottom: 1rem;
				margin-right: 0;
			}

			.btn + br + .btn {
				margin-left: 6%;
			}

		}
		
	}
	
}