.page-template-template-contact {
	
	.frm_form_fields {
	
		margin-bottom: 6rem;
		position: relative;
	
	}
	
	.block-heading {
	
		color: #0b2d49;
		font-family: "Source Sans Pro",sans-serif;
		margin-top: 6rem;
		text-align: center;
			
	}
	
	#frm_field_218_container .block-heading {
	
		margin-top: 0;
	
	}

	.three-and-two{
		display: flex;
		flex-flow: row wrap;
		justify-content: center!important;
		width: 75%!important;
		.frm_radio{
			width: calc(33% - 2rem)!important;
		}

		@media only screen and (max-width: 1280px) and (min-width: 850px) {
			width: 100%!important;
			.frm_radio{
				width: calc(50% - 2rem) !important;
			}
		}

		@media screen and (max-width: 850px){
			width: 100%!important;
			.frm_radio{
				width: 100%!important;
			}
		}
	}
	.frm_hidden_container{
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		.frm_primary_label{
			width: 0;
		}	
	}	
	.frm_radio {	

		text-align: center;
		width: calc(25% - 2rem) !important;	
		
		&:hover label {
			
			color: #fff !important;

		}

		@media only screen and (max-width: 1280px) and (min-width: 850px) {
			width: calc(50% - 2rem) !important;	
		}
		
		@media (max-width: 850px) {
			
			width: 100% !important;	
			height: fit-content;
		}
			
	}
	
	.frm_text_label_for_image,
	label {

		color: #0c304e !important;
		font-family: Montserrat,sans-serif !important;
		font-size: 2rem;
		font-weight: 700;
		
		@media (max-width: 768px) {
			
			font-size: 1.5rem;	
			
		}

	}
	
	/* How can we help you? */
	
	.pane-radio {
	
		.block-heading {
		
			margin-top: 12rem;
		
		}

		.frm_empty_url {
			display: none;
		}
	
		.frm_image_option_container {
			
			padding: 4rem;
			
			@media (max-width: 767px) {
				
				padding: 2rem;	
				
			}

		}
		
		.frm_image_option_container:hover,
		&.frm_image_options input[type=radio]:checked+.frm_image_option_container {
		
			background: #0c304e;

			.frm_text_label_for_image {
			
				color: #fff !important;
				
			}

			svg path[fill='#1c5079'],
			svg path[fill='#0c304e'],
			svg path.st1 {
				fill: #31beaf;
			}
		
		}

		&.frm_image_options .frm_opt_container {
		    display: inline-flex;
		    flex-flow: wrap;
		    flex-direction: row;
		    width: 100%;
		    justify-content: center;
		    margin: auto;
		}

		svg {
		
			margin: 0 auto;
		    max-width: 110px;
		    @media screen and (min-width: 768px){
		    	margin: 0 auto 4rem auto;
		    }
		}

	}
	
	/* Which services are you interested in? */
		
	.vertical_radio {
			
		.frm_opt_container {
			
			display: flex;
			flex-wrap: wrap;

			&.services-interested-in{
				justify-content: center;
	
				input {
					visibility: hidden;
					width: 0;
					position: absolute;
				}
	
				label {

					font-family: Montserrat,sans-serif;
					font-size: 2rem;
					font-weight: 700;
					padding: 4rem;
					text-indent: 0;
			
					@media (max-width: 767px) {
				
						font-size: 1.6rem;	
						padding: 2rem;
				
					}
				}
			}
			
		}
		
		.frm_radio {	
			
			align-items: center;
			background: #31beaf;
			border-radius: .5rem;
			display: flex;
			justify-content: center;
			margin: 0 1rem 2rem 1rem;
			
			&:hover {
				
				background: #0b2d49;
				
				label {
					
					color: #fff;
					
				}
				
			}
					
		}
		

		
	}
	
	/* Send us a message */
	
	.actual-form-container {

		background: #E6EAED;
		border-radius: .5rem;
		margin: 0 auto;
		max-width: 94%;
		padding: 4rem;
		width: 102rem;

		@media only screen and (max-width: 1280px) {
			width: 100%;
		}
		
		.vertical_radio {
		
			padding: 0;
		
			label {
			
				font-size: 1.3rem;
				padding: 0;
				padding-left: 2rem;
			
			
			}
		
		}
			
		input,
		textarea {

			padding: 2rem 1rem;
			visibility: visible;

		}
		
		.frm_checkbox {
		
			width: 100%;
			

		
		}
			
		@media (max-width: 768px) {

			.form-field {

				grid-column: span 12 / span 12;

			}

		}
		
	}
	
	.frm_submit {

		margin: 0 auto;
		max-width: 94%;	
		
	}

	.with_frm_style .frm_message, 
	.frm_success_style {
	
		background: #31beaf;
		margin-bottom: 6rem;
		padding: 2rem;
		
		p {
		
			color: #fff;
			font-family: Montserrat,sans-serif;
			font-size: 2.7rem;
		
		}
	
	}

	&.direct-security-toggle {
		.frm_submit {

			margin: auto;
			max-width: none;	
			
		}
	}

}

.frm_style_formidable-style.with_frm_style .frm_submit button {

	background: #0c304e url(../../assets/images/arrow-white-right.svg) no-repeat calc(100% - 1rem) center;
	border: none;
	color: #fff;
	
	&:hover {
	
		background: #04111c;
		color: #fff;
	
	}

}

.other-info-contact{
	margin-bottom: 6rem;
	.contact__image{
		width: 100%;
		object-fit: cover;
	}
	.bg-blue{
		padding: 3rem;
		border-radius: 10px;
		width: calc(50% - 1.5rem);
		@media screen and (max-width: 768px){
			width: 100%;
			margin-bottom: 3rem;
		}
	}
}

#contact-submit-btn{
	display: none;
}

.actual-form-container{
	display: none;

}
.frm_selected_checkmark{
	z-index: 90!important;
}