.page-template-template-direct-security-page {
	
	.google-review-block {
	
		
		@media (max-width: 1024px) {
			
			
			margin-top: 0;	
			
		}
	
	}
	
	.text-image {
	
		padding-top: 12rem;
	
	}
	
	.bg-white.block {
	
		padding-top: 9rem;
	
		h2 {
		
			margin-bottom: 1.5rem;
		
		}

	
	}
	
	.post-card {

		
		&__title:before {
	
			content: url(../../assets/images/chevron-right-green.svg);

			
		}
		
	}

	@media (max-width: 667px) {
	
		.bg-white:not(.block) {
			
			position: relative;
			
			&:before {

				background: #0b2d49;
				display: block;	
				content: "";
				height: 25%;
				position: absolute;
				top: 0;
				width: 100%;

			}				

			@media (max-width: 1024px) {
				&:before {
					background: none;
				}
			}

			div:not(.side-map-container__map) {
				.row {
					
					position: relative;
					
					& > div:first-of-type {
						
						// order: 1;
						
					}
					
					& > div:last-of-type {
						
						margin-bottom: 4rem;
						// order: -1;
						
					}
				
				}
			}

				
		}
	
	}
}