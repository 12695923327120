#vacancies {
	
	.row + .row > div  {
		
		margin-bottom: 3rem;
		
		.bg-light {
		
			border-radius: .5rem;
			height: 100%;
			padding: 2rem 2rem 6rem 2rem;
			
		}
		
		a {
			
			bottom: 2rem;
			font-weight: 600;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			
		}
		
	}
	
}