.more-links-footer {
	
	padding: 6rem 0;
	
	@media (max-width: 768px) {
		
		padding: 4rem 2rem;
		
		div[class^="col-"], 
		div[class*="col-"] {
		
			padding-bottom: 3rem;
			text-align: left !important;
			
			&:last-of-type {
				
				padding-bottom: 0;
			
			}
		
		}
		
	}
	
	.btn {
		
		text-align: left;
		margin: 0 0 2rem 0;
		width: 100%;
				
	}
	
	a {
	
		font-weight: 600;

		
	}
	.name{
		
			&:not(:first-child){
				a{
					text-decoration: none!important;
				}
				
			}
		
	}

	p{
		a{
			color: #0c304e!important;
			&:hover{
				color: #31beaf!important;
			}
		}
	}

	h3 {
	
		font-size: 2.9rem;
	
		+ ul,
		+ .btn {

			margin-top: 2rem;

		}
		
		@media (max-width: 768px) {

			text-align: left;

		}
		
	}
	
	.two-col-list {
		
		list-style: none;
		padding: 0;
		
		@media (max-width: 768px) {
		
			max-width: 300px;
		
		}
		
		li {
			
			display: flex;
			line-height: 1.8;
			margin-bottom: 1rem;
			font-size: 1.4rem;
			
			span {
				
				flex-grow: 1;
				width: 50%;
				font-weight: bold;
				
			}
			
		}
		         
	}
	
}