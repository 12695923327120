/* High Court Enforcement / Our People / Latest News / Testimonials / Contact / Cookie Policy */

.hero--plain {
	// background: #0b2d49 url(../../assets/images/plain-hero-background.png) 100% 100% no-repeat;
	// background-size: 100%;
	
	& + div {
		
		padding-top: 3rem;
		
		@media (min-width: 768px) {
		
			padding-top: 6rem;
			
		}
	
	}
	
	.container {

		padding-bottom: 5rem;
		padding-top: 6rem;

	}
	
	.col-md-1 {
		
		/*border: 10px solid red;
		height: 100%;
		position: absolute;*/
		
		img {
			
			/*height: 100%;*/
			// max-width: none;
			@media only screen and (max-width: 1366px) and (min-width: 800px) {
				min-width: 70px;
			}
		}
		
	}
	
	.col-md-5,
	.col-md-6 {
		
		margin-left: 2rem;
		z-index: 2;
		
		@media (max-width: 800px) {
			
			// margin-left: 0;	
			
		}
		
	}

	.hero__chevron-large {
		
		/*left: 50%;
		transform: translateX(-90%);*/
		right: 0;
		max-width: unset;
		// width: 50%;

		// @media (max-width: 1200px) {
		// 	height: 80%;
		// }

		// @media (max-width: 948px) {
		// 	height: 60%;
		// }

		// @media (max-width: 850px) {
		// 	display: none;
		// }

	}
		
	h1 {
	
		font-size: 5.8rem;
		
		@media (max-width: 1024px) {
			
			font-size: 3rem;
			
		}
		
	}

}

/* Contact / Cookie Policy */

.page-template-template-contact,
.page-template-default,
.page-template-template-thankyou,
.page-template-template-form-payment {
	
	.hero--plain {

		.alpha {
			margin-bottom: 0;

			& + p {
				margin-bottom: 45px;
			}
		}
	
		.container {

			padding-bottom: 4rem;
			padding-top: 4rem;	

		}
		
		.row {

			align-items: center;

		}
		
	}
			
}

/* News */
body.page-template-template-dss-news,
.blog,
// .archive,
.single {
	
	.hero--plain {

		.alpha {
			margin-bottom: 0;

			& + p {
				margin-bottom: 45px;
			}
		}

		h1.alpha {
			font-size: 4rem;
			line-height: 4.5rem;
		}
	
		.container {

			padding: 0;

		}
		
		.row {

			align-items: center;

		}
		
	}
			
}

.page-template-template-about-us {
	.bg-blue {
		// background: $teal;
	}
}

.post-template-default.single {
	.hero--plain {
		h2.alpha {
		    margin-top: 4.5rem;
		    margin-bottom: 4.5rem;
		    line-height: 4.5rem;
		}
	}
}