.post-type-archive-team {
	
	.col-md-10.block {
		
		padding-bottom: 6rem;
		padding-top: 0;
	
	}
	
	form {
		
		h4 {
			
			display: none;
			
		}
		
		ul {
			
			align-items: center;
			display: flex;
			margin: 0;
			padding: 0;
			
			@media(max-width: 1150px) {
				
				align-items: flex-start;
				flex-direction: column;	
				
				li {
					
					margin-bottom: 2rem;
				
				}
				
			}
			
		}
		
		li {
			
			float: left;
			margin-right: 4rem;
			padding: 0;
			width: 30%;
			
			@media(max-width: 767px) {
			
				width: 100%;
				
			}
		
		}
		
		label {
			
			width: 100%;
			
		}
		
		.sf-input-text {

			background: url(../../assets/images/search-icon.svg) no-repeat calc(100% - 2rem) center;
			font-style: italic;
			padding-right: 5rem;

		}
		
	}
	
	.bg-white {
		
		margin-bottom: 6rem;
		
	}
	
	.gridder--three > div:not(.gridder-show) {

		margin-right: 0;

	}
	
	.gridder-expanded-content {
		
		padding: 2rem;
		
	}

	.team-card {

		@media(max-width: 768px) {

			.row {

				flex-direction: column;
				text-align: center;

			}

			.col-6 {

				max-width: 100%;

			}

		}

		@media(max-width: 1024px) {
			.row {

				flex-direction: column;
				text-align: center;

			}

			.col-6 {

				max-width: 100%;

			}

		}

		&__image-wrapper {

			margin: 0 auto;
			max-width: 20rem;
			position: relative;
			// padding-right: 2rem; //NEW

			@media only screen and (max-width: 1820px) and (min-width: 1025px) {
				//padding-right: 3rem; //NEW
			}

			&:after {
				content: '';
				background-image: url(../../assets/images/team-arrows.png);
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				display: block;
				right: -1.5rem; //3.5rem
				position: absolute;
				top: 4rem;
				width: 54px;
				height: 66px;
				z-index: 2;


				@media only screen and (max-width: 1820px) and (min-width: 1541px) {
					right: 0rem;
					width: 48px;
					height: 60px;
				}

				@media screen and (max-width: 1540px) {
					right: 0rem;
					width: 44px;
					height: 56px;
				}

				@media(max-width: 1024px) {
					right: -0.5rem;
				}

			}			

		}

		&__image {

			padding: 0;
			position: relative;

			img {
				display: block;
				width: 100%;
				max-width: 100%;
				border-radius: 50rem;

			}

		}
		&__content {
			padding-left: 1rem;
			padding-right: 1rem;
			@media(max-width: 1024px) {
				padding-right: 0;
			}
		}

		h4 {

			margin-bottom: -1rem;
			margin-top: 3.5rem;
			

		}

		.link {

			color: #31beaf;

		}

		&.selectedItem {

			.team-card__image-wrapper {

				&:before {

					background: rgba(49,190,175,0.4);
					border-radius: 20rem;
					content: '';
					height: 100%;
					left: 0;
					// max-width: 18rem;
					position: absolute;
					top: 0;
					width: 100%;
					z-index: 1;

					@media only screen and (max-width: 1820px) and (min-width: 1540px) {
						// max-width: 16.5rem;
					}
					@media only screen and (max-width: 1539px) and (min-width: 1367px) {
						// max-width: 14rem;
					}
					@media only screen and (max-width: 1366px) and (min-width: 1025px) {
						// max-width: 17rem;
					}
				}

			}

		}

	}	
	
	.text-image .btn {
		
		background: #0b2d49;
		color: #fff !important;
		
		&:after {
			
			content: url(../../assets/images/button-arrow-right-white.svg);
			
		}
		
		&:hover {
			
			background: #04111c;
			
		}
		
	}

	main > .text-image.bg-white {
		div.objectfit img {
			max-height: 364px;
		}
	}
	
}