/* Home Page Only */

.hero--slider {
	
	background: #0C304E;
	
	.slide {
		
		@media (max-width: 850px) {
			
			display: flex !important;
			flex-direction: column-reverse;
			
		}
		
	}

	@media (max-width: 768px) {
		.slick-slide {
			&:nth-child(1) {
				&.slick-active {
					.slide__tabs {
						.slick-dots {
							li:nth-child(1) {
								background: #0ff;
							}
						}
					}
				}
			}

			&:nth-child(2) {
				&.slick-active {
					.slide__tabs {
						.slick-dots {
							li:nth-child(2) {
								background: #0ff;
							}
						}
					}
				}
			}

			&:nth-child(3) {
				&.slick-active {
					.slide__tabs {
						.slick-dots {
							li:nth-child(3) {
								background: #0ff;
							}
						}
					}
				}
			}

			&:nth-child(4) {
				&.slick-active {
					.slide__tabs {
						.slick-dots {
							li:nth-child(4) {
								background: #0ff;
							}
						}
					}
				}
			}

			&:nth-child(5) {
				&.slick-active {
					.slide__tabs {
						.slick-dots {
							li:nth-child(5) {
								background: #0ff;
							}
						}
					}
				}
			}

		}
	}	
	
	.container {
		
		height: 100%;
		padding: 12.5rem 1.5rem 12.5rem 1.5rem;
		position: relative;
		z-index: 2;
		
		@media (max-width: 1400px) {
		
			padding: 8.5rem 1.5rem 9.5rem 1.5rem
			
		}
		
		@media (max-width: 1200px) {
		
			padding: 3.5rem 1.5rem 6rem 1.5rem;
			
		}
		
		@media (max-width: 768px) {

			padding: 2.5rem 1.5rem 2rem 1.5rem;
						
		}
				
		@media (max-width: 450px) {

			padding: 2.5rem 1.5rem 0rem 1.5rem;
						
		}
				
		& + img {

			position: absolute;
			right: 0;
			top: 0;
			width: auto;
			height: 100%;
			clip-path: polygon(80% 0, 95% 50%, 80% 100%, 0% 100%, 13% 50%, 0% 0);

			@media (max-width: 1366px) {
				// clip-path: polygon(80% 0, 95% 50%, 80% 100%, 35% 100%, 50% 50%, 35% 0);
				// clip-path: polygon(80% 0, 95% 50%, 80% 100%, 40% 100%, 55% 50%, 40% 0);
				// clip-path: none;
			}

			@media (max-width: 1440px) {
				// clip-path: polygon(80% 0, 95% 50%, 80% 100%, 35% 100%, 50% 50%, 35% 0);
				// clip-path: polygon(80% 0, 95% 50%, 80% 100%, 40% 100%, 55% 50%, 40% 0);
				// clip-path: none;
				right: 2%;
			}
			
			@media (max-width: 1024px) {
				// width: 100%;
				// clip-path: polygon(100% 0, 100% 50%, 100% 100%, 50% 100%, 50% 50%, 50% 0);
				// right: 10%;
				// clip-path: none;
				right: 2%;
			}

			@media (max-width: 850px) {
				position: static;
				width: 100%;
				height: auto;
				clip-path: none;
			}

			@media (max-width: 768px) {
				height: 350px;
				object-fit: cover;
			}

			@media (max-width: 550px) {
				height: 300px;
			}

			@media (max-width: 480px) {
				height: 250px;
			}

			@media (max-width: 380px) {
				height: 220px;
			}


		}

		.row {
			> div {
				&:first-child {
					@media (max-width: 1440px) {
						// display: none !important;
					}
				}
			}
		}

	}
	
	.slide__title {
	
		color: #fff !important;
		font-size: 5.8rem;
		line-height: 1.1;
		margin-bottom: 2rem;
	   	padding-right: 4rem;

	    @media only screen and (max-width: 1600px) and (min-width: 1367px) {
	    	padding-right: 2rem;
	    }

		@media only screen and (max-width: 1366px) and (min-width: 1025px) {
			font-size: 4.5rem;
		}

		@media only screen and (max-width: 1024px) and (min-width: 768px) {
			font-size: 4rem;
			margin-bottom: 1rem;
		}

		@media only screen and (max-width: 1366px) and (min-width: 768px) {
			padding-right: 8rem;
		}

		@media (max-width: 767px) {
			font-size: 4rem;
		}
		
		& + p {
		
			color: #fff;
			font-size: 1.6rem;
			line-height: 2.6rem;
			
			@media (max-width: 1024px) {
				
				font-size: 1.4rem;
				line-height: 1.8rem;				
			}

			@media (max-width: 768px) {
				
				font-size: 1.3rem;
				line-height: 1.7rem;				
			}
			
			@media (max-width: 600px) {
				font-size: 1.6rem;
				line-height: 2.6rem;

			}
		}
		
	}
	
	ul {
		
		padding: 0;
		
	}
	
	li {
		
		display: inline-block;
		list-style-type: none;
		margin-right: 3rem;
		
		@media (max-width: 768px) {
			
			background: #fff;
			height: .2rem;
			margin-right: 2rem;
			width: 3rem;
			
			&.slick-active {
				
				background: aqua;
				
			}
			
			.pager__item {

				display: none;

			}

		}

		@media (max-width: 1024px) {
			margin-bottom: 1rem;
		}
		
	}
	
	a {
		
		color: #31beaf;
		font-size: 1.6rem;

		@media (max-width: 1024px) {
			font-size: 1.4rem;
			line-height: 2.5rem;
		}
		
		@media (max-width: 768px) {
			font-size: 1.2rem;
			line-height: 2.3rem;
		}
		
		@media (max-width: 600px) {
			font-size: 1.6rem;
			line-height: 2.3rem;
		}
	}
	
	.slide__tabs {
		
		margin: 7.5rem 0 0 0;

		@media (max-width: 1440px) {
			margin: 4.5rem 0 0 0;
		}

		@media (max-width: 1024px) {
			margin: 2.5rem 0 0 0;
		}

		@media (max-width: 768px) {
			margin: 1.5rem 0 0 0;

		}

		a {
			
			color: #fff;
			text-decoration: none;
			font-size: 1.3rem;
			line-height: 2.5rem;

			@media (max-width: 768px) {
				font-size: 0.7rem;
				line-height: 1.3rem;
			}
			
			&:hover {
				
				color: #31beaf;
			
			}
			
		}
				
	}
	
	[data-slick-index="0"] [data-slide-index="0"],
	[data-slick-index="1"] [data-slide-index="1"],
	[data-slick-index="2"] [data-slide-index="2"],
	[data-slick-index="3"] [data-slide-index="3"],
	[data-slick-index="4"] [data-slide-index="4"],
	[data-slick-index="5"] [data-slide-index="5"] {

		border-bottom: 2px solid #31beaf;
		color: #31beaf;
		padding-bottom: 1rem;
			
	}

	.hero__chevron-small { // USING CLIPPING PATH
		display: none;
	}
	
	.hero__chevron-large {
		
		width: 55%;
		
		@media only screen and (max-width: 1024px) and (min-width: 768px) {
			width: 60%; 
		}

		@media (max-width: 850px) {
			display: none;
		}

	}

	.slide > .container > .row > .col-md-6 {
		@media (max-width: 850px) {
			flex: 0 0 100%;
		    max-width: 100%;
		}
	}
			
}