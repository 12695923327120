.page-template-template-direct-security,
.page-template-template-direct-security-page {
	
	h1 {
	
		font-size: 5.8rem;

		@media (max-width: 1000px) {

			font-size: 4rem;

		}	
	}
	h2{
		&.heading-padding{
			margin-bottom: 4.5rem;
		}
	}
	
	h3 {
	
		font-size: 2.9rem;
	
	}
	
	.bg--white {
		
		.two-col-buttons {
			
		    padding: 4rem 0 3rem 0;
				
			.btn {
	
				margin: 0 2rem 2rem 0;
				width: calc(50% - 2rem);

				&:nth-child(even) {

					margin: 0;

				}

				@media (max-width: 1400px) {
					margin: 0 1.5rem 1.5rem 0;
					width: calc(50% - 1.5rem);

				}

				@media (max-width: 600px) {
					margin: 0 0 1.5rem 0;
					width: 100%;
	
					&:nth-child(even) {

						margin: 0 0 1.5rem 0;

					}
				}


			}
			
		}
		
		.bg-opposite {
	
			border-radius: .5rem;
			padding: 3rem 3rem 3rem 3rem;
			
			.btn {
				
				margin-top: 3rem;
				
			}
			
			img {
				
			    margin: 0 auto;
			    display: block;

			}
				
			.form-container__accreditations{
				img{
					padding: 0;
				    width: 50px;
				    margin: 1rem 2.3rem;
				    display: inline;
				}
			}
			
		}
		
	}
	
	.google-review-block {
		

		p{
			margin: 2rem 0;
		}
	
	}
	
	.text-image {

		padding: 12rem 0 0rem 0;

		@media (max-width: 1000px) {
			padding: 8rem 0 0rem 0;
		}
		
	}
	
	.block.bg-light {
		
		padding-bottom: 2rem;
		
		h2 {
		
			font-size: 4.5rem;
			font-weight: 300;

			@media (max-width: 450px) {
				font-size: 2.9rem;
				line-height: 3.8rem;
			}
		
		}
		
		.card__backside a {
			
			display: none;
		
		}
		
	}
	
	.bg--light {
	
		padding-bottom: .3rem;
	
	}
	
	.bg-split--light-to-white {

		padding-top: 3rem;
		
	}

	.bg--blue, .bg-blue {
		
		background: #0b2d49;
	}
	.bg--blue.hero, .bg-blue.hero{
		background: #1A507A;
	}
	
}

.page-template-template-dss-news {
	.bg--blue.hero, .bg-blue.hero{
		background: #1A507A;
	}
}