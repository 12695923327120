// MODAL
// -----------------------------------------------------------------------------

.timed-modal {
	display: none;

	&--open {
		display: block;
	}

	#lottie-envelope-animation--popup {
		margin: -8rem auto -3rem;
		width: 90%;
		max-width: 300px;

		@media screen and (max-width: 768px) {
			display: none;
		}

	}
	
	.backdrop {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 98;
		background: rgba(black, .5);

		@media screen and (max-width: 768px) {
			// display: none;
		}

	}

	&__container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99;

		@media screen and (max-width: 768px) {
			top: 50%;
	        transform: translateY(-50%);
	        bottom: auto;
			height: auto;
		}
	}

	&__inner {
		width: 71rem;
		padding: 3rem 2.8rem 0;
		background-color: #fff;
		overflow: hidden;

		@media screen and (max-width: 768px) {
			box-shadow: 0px 1px 8px -2px rgba(0, 0, 0, 0.5);
		}
		
	}
}

#testimonialModal {
	.modal-footer {
	    display: block;

	    .btn {
	    	padding: 0.9rem 1.3rem 0.7rem 1.3rem;
	    }
	}
}