[class^="direct-security"], [class*="direct-security"] {
	
	.site-header {
		
		li {

			@media (min-width: 1350px) {

				&.show > a,
				&:hover > a,
				&.active > a {

					padding-bottom: 4rem;

				}

			}

		}
		
	}

}