.block.bg-light {
			
	.color-teal {
		
		color: #31beaf;
		
	}
	
	.row.steps {
		
		margin-top: 3.5rem;
		padding-top: 2rem;
		
	}
	
}