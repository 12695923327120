.single {
	.article--content {
		h1, .wysiwyg--h1, h2, .wysiwyg--h2, h3, .wysiwyg--h3, h4, .wysiwyg--h4 {
			font-family: "Source Sans Pro",sans-serif;
			margin: 2rem 0 0 0;
		}

		h1, .wysiwyg--h1 {
			font-size: 58px;
			font-weight: 700;
			color: #31BEAF;	

			&:first-of-type {
				color: #0C304E;	
			}

			@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
				font-size: 45px;
			}
			@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
				font-size: 30px;
			}
			@media screen and (max-width: 767px){
				font-size: 40px;
			}

		}
			
		h2, .wysiwyg--h2 {
			font-size: 29px;
			font-weight: 700;
			color: #0C304E;	
		}

		h3, .wysiwyg--h3 {
			font-size: 23px;
			font-weight: 700;
			color: #0C304E;	
		}

		h4, .wysiwyg--h4 {
			font-size: 19px;
			font-weight: 700;
			color: #232323;
		}
	}
}


a:not(.btn) {

	color: #0C304E;	
	text-decoration-thickness: .1rem!important;

	&:hover {

		color: #31beaf;

	}

}

.min-height-500{
	min-height: 850px;
	padding-bottom:8rem;

	@media screen and (max-width: 1024px){
		min-height: auto;
		padding-bottom:6rem;
	}

}

.no-min-height-fix {
	min-height: 0px !important;
}

.min-height-800{
	min-height: 800px;
	padding-bottom:10%;
}

// .retract{
// 	margin-top: 0;
// 	@media screen and (min-width: 768px){
// 		margin-top: -6rem;
// 	}
// }

.google-review-block{
	&.retract{
		@media screen and (min-width: 1400px){
			margin-top: -15rem;
		}
	}
	&.block{
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

p {
	
	line-height: 3rem;
	a:not(.btn) {

	color: #31beaf;	
	text-decoration-thickness: .1rem!important;

	&:hover {

		color: #000000;

	}

}
	
}

/* About Us (Accreditations) + Working At DCBL (Current Vacancies) */

.bg-blue {

	.color-teal {

		color: #31beaf;

	}

}

.big-underline {

	border-bottom: .1rem solid #0b2d49;
	font-size: 5rem;
	font-weight: 300;
	margin-bottom: 4rem;
	padding-bottom: 3rem;

	@media (max-width: 450px) {
		font-size: 2.9rem;
		line-height: 3.8rem;

	}
}

.block-heading {
	
	color: #0B2D49;
	font-family: "Source Sans Pro",sans-serif;
	font-size: 5rem;
	font-weight: 300;
	
	@media (max-width: 767px) {
	
		font-size: 2.3rem;
	
	}
	
}

.block-header {

	margin-bottom: 1rem;
	font-size: 4.5rem;
    line-height: 3.8rem;

    @media (max-width: 768px) {
		font-size: 2.9rem;
	    line-height: 3.8rem;
    }

	&.underline {
		border-bottom: 1px solid #31beaf;
	}

	&.extra-space {
		padding-bottom: 2rem;
		margin-bottom: 0rem;
	}

	+ p {
		
		margin: 0 auto 6rem auto;
		max-width: 70rem;
		
	}
	
}

/* Text Side */

.negative-overlap {
	padding-bottom: 20rem;
}

/* Pagination */

.pagination {
	margin-top: 2rem;
	text-align: left;
}

/* Chevrons */

.chevron-divider {

	margin-bottom: 6rem;
	position: relative;
	text-align: center;
	
	@media (max-width: 768px) {

		margin-bottom: 2rem;

	}

	&__image {
		display: inline;
		background: #fff;
		padding: 0 5rem;
		position: relative;
		z-index: 1;

	}

	&:after {

		border-top: .1rem solid #7AD9CE;
		content: '';
		display: block;
		height: 1.2rem;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;

	}

	& + h2 {
	
		font-size: 5rem;
		font-weight: 300;
		
		& + p {
		
			max-width: 900px;
		
		}
	
	}

}

.bg-light .chevron-divider .chevron-divider__image {

	background: #e6eaed;

}

.page-links-block__heading {

	p {

		margin: 2.5rem auto 6rem auto;
		max-width: 72rem;

	}

}

.no-left-pad {
	
	padding-left: 0;
	@media screen and (max-width: 768px) { // was 480
		padding-left: 15px;
	}

}

/* Videos */

.video-container {

	margin-top: 3rem;

	&__cover {
	
		height: 100%;
		width: 100%;
		object-fit: cover;	
		left: 0;
		position: absolute;
		top: 0;

	}

	&__play {
		
		background: red;	
		border-radius: 5rem;
		position: absolute;
		transform: translate(-50%, -50%);
		
	}

}

.trustpilot-image {
	display: block;
	width: 420px;
	max-width: 100%;
	margin: 0 auto;
}

.trustpilot-widget{
	max-width: 1020px;
	margin: 0 auto;
}

.ds-toggled.bg-blue{
	background-color: #1a507a!important;
}

.padded-underline{
	padding-bottom: 6rem;
	border-bottom: solid 2px #0c304e;
}
#menu-item-1183{
	&::before{
		content: "";
		background: url("../../assets/images/arrow-right-blue.svg") no-repeat;
		background-size: contain;
		transform: translateY(-50%) rotate(90deg);
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		right: -40%;
		top: 65%;
	}
}

.trustpilot-wrapper{
	@media screen and (max-width: 1060px){
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.steps-header{
	@media screen and (max-width: 768px){
		font-size: 4rem;
	}
}

.form-container__accreditations{
	@media screen and (max-width: 450px){
	    font-size: 1.5rem;
	}

	img{
		margin: 1rem 1.5rem;

		@media screen and (max-width: 768px){
			margin: 1rem 3.5rem;
		}

		@media screen and (max-width: 600px){
			margin: 1rem 3rem;

			&:nth-child(n+7):nth-child(-n+8) {
				display: none;
			}
		}

		@media screen and (max-width: 450px){
			margin: 1rem 0.7rem;
		}
	}
}

.no-padding{
	padding:0!important;
}

.privacy-policy main .col-lg-10 p[id]:not([id=""]) {
	scroll-margin-top: 10rem;
}