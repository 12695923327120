//******************************************************************************
// SLIDER
// Carousel styles
//******************************************************************************

.slick-slider {
	.slick-track {
		display: flex;
		height: auto;
	}

	.slick-slide {
		height: auto;
	}

	.slick-arrow {
		background-color: transparent;
		font-size: 0;
		padding: 0;
		border-radius: 50%;
		border: none;
		position: absolute;
		z-index: 1;
		top: 50%;
		transform: translate(0, -50%);
		cursor: pointer;

		&:hover {
			background-color: var(--textclr);
		}

		&:before {
			content: "";
			font-size: 2rem;
			color: white;
			padding: 1rem;
		    display: block;
		    line-height: 1;
		    height: 4rem;
		    width: 4rem;
		}

		&.slick-prev {
			left: 2rem;

			&:before {
				content: "";
				background: url('../assets/images/arrow-left-white.svg') no-repeat center center / 24px;
			}
		}

		&.slick-next {
			right: 2rem;

			&:before {
				content: "";
				background: url('../assets/images/arrow-left-white.svg') no-repeat center center / 24px;
				transform: rotate(180deg);
			}
		}

		@media(max-width: 830px) {
			position: absolute;
			left: auto;
			right: 2rem;
			bottom: 0;
			top: auto;
		}
	}
}

.home {
	.pager__item {
		cursor: pointer;
	}
	
	@for $i from 0 through 5 {
		[data-slick-index="#{$i}"] {
			[data-slide-index="#{$i}"] {
				color: red;
			}
		}
	}
}