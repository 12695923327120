.page-template-template-overview {
			
	.text-image {
		
		background: #e6eaed;
		padding-bottom: 6rem;
		padding-top: 6rem;
		
		@media (max-width: 768px) {
			
			padding-bottom: 3rem;
			padding-top: 3rem;			
			
		}
		
		p strong {
			
			font-size: 2.9rem;
			
		}
		
	}
	
	.form-container__form {
		
		.frm_radio.frm_image_option {
			
			width: calc(50% - 20px) !important;
			
		}
		
	}
	
	.side-map-container {
		
		&.bg-opposite {
		
			border-radius: 0.5rem;
			padding: 3rem;	

			@media (max-width: 600px) {
			    // margin-top: -30rem;
			}
		
		}
	
		&__heading {
			
			font-family: "Source Sans Pro",sans-serif;
			font-size: 2.7rem;
			margin-bottom: 2rem;

		}
		
		ul {
			
			padding: 0;	
			
		}
		
		li {
			
			font-weight: 600;
			list-style: none;
			margin-bottom: 3rem;
			text-transform: left;
			
			&:last-of-type {
				
				margin-bottom: 0;	
				
			}
			
			
		}
		
	}
	
	.cards {
	
		.block {
		
			div[class^="col"], div[class*="col"] {

				margin-bottom: 3rem;

			}
			
		}

		.card {
			
			background: none;
			height: 100%;
			
			&--flip {
				
				height: 100%;
				
				.flip-area {
					
					height: 100%;
					
				}
				
			}
						
			&__frontside {
				
				background: #31beaf;
				height: 100%;
				
				img {

					margin-bottom: 3rem;

				}
				
				.card__link {
					
					color: #0b2d49 !important;
					
				}

			}
			
			&__backside {
				
				height: 100%;
				
				.card__link {
					
					color: #31beaf !important;
					
				}

			}
			
		}
		
	}
		
	@media (max-width: 667px) {
	
		.bg-white:not(.block) {
			
			position: relative;
			
			&:before {

				background: #0b2d49;
				display: block;	
				content: "";
				height: 25%;
				position: absolute;
				top: 0;
				width: 100%;

			}				

			@media (max-width: 1024px) {
				&:before {
					background: none;
				}
			}

			div:not(.side-map-container__map) {
				.row {
					
					position: relative;
					
					& > div:first-of-type {
						
						// order: 1;
						
					}
					
					& > div:last-of-type {
						
						// margin-bottom: 4rem;
						// order: -1;
						
					}
				
				}
			}

				
		}
	
	}
	
	// .retract {
		
	// 	margin-top: 6rem;
		
	// 	@media (min-width: 1350px) {
			
	// 		margin-top: -8rem;
			
	// 	}
		
	// }
		
	.hover-flip-cards.block.bg-light {
		
		padding-bottom: 0;
		
		h2 {
		
			font-size: 4.5rem;
			font-weight: 300;

			@media (max-width: 768px) {
				font-size: 2.9rem;
				line-height: 3.8rem;

				strong {
					display: block;
				}
			}
		
		}
				
	}


	.hero+.bg-white+.bg-blue {
		@media (min-width: 1200px) {
			margin-top: -12rem;
		}
	}
}