.site-footer {
	
	padding: 4rem 0;
	
	@media (max-width: 768px) {

		.col {

			flex-grow: 0;
			
		}
		
	}
	
	p, a, strong, .copyright {
	
		font-size: 1.3rem;
		line-height: 2rem;
	
	}
	
	a {
		
		text-decoration: none;
		
	}

}

.site-footer__top {
	
	.d-flex {
		
		flex-wrap: wrap;
		
	}
		
	img {
		
		margin: 0 1.5rem 3rem 1.5rem;
		
		@media (max-width: 1024px) {

			max-width: 7.7rem;
			
		}

		@media (max-width: 768px) {

			max-width: 4.7rem;
			
		}

		@media screen and (max-width: 480px) {

			width: auto;
		    max-width: 90px;
		    max-height: 100px;

		    &:nth-child(n+5) {
		    	width: auto;
			    max-width: 90px;
			    max-height: 100px;
		    }

		}

	}
	
	p {
		
		text-align: center;
		width: 100%;
		padding: 0 2rem;
	}
	
}

.site-footer__middle {
	
	border-bottom: .2rem solid #e6eaed;
	border-top: .2rem solid #e6eaed;
	margin: 3rem 0;
	padding: 4rem 0;
	
	a {

		// display: block;
		margin-top: 2rem;
		
	}
	.location-link{
		margin-top: 0;
	
	}
	
	
	@media (max-width: 768px) {

		.col {
			
			flex-basis: 50%;
			margin: 0 0 4rem 0;
			
			&:last-of-type {
				
				margin: 0;
				
			}
			
		}

	}	
	
}

.site-footer__bottom {
	
	@media (max-width: 768px) {
		
		flex-direction: column;	
		
		.col > div {
		
			margin-bottom: 2rem;
			
			&:first-child {

				margin-bottom: 4rem;

			}
			
		}
		
	}
	
	.menu {
		
		padding: 0;
		
	}
	
	li {
		
		display: inline-block;
		list-style-type: none;
		margin-right: 2rem;
		
	}
	
	.copyright a {
		
		text-decoration: underline;
		
	}
	
	.col {

		flex-grow: 2;
		
		&:last-of-type {
			
			flex-grow: 1;

			@media (min-width: 769px) {

				text-align: right;
				
				a {
					
					margin-left: 2rem;
					
				}

			}

			@media (max-width: 768px) {

				margin-top: 2rem;
				
				a {
					
					margin-right: 2rem;
					
				}

			}

		}
		
	}
	
}