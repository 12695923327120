/* Cookie Policy */

.page-template-default:not(.home) #content {
			
	.hero--plain+div {
	
		padding-bottom: 6rem;
		
	}			
			
	a {
		
		color: #31beaf;
		
	}
		
	.cookielawinfo-row-cat-table {
		
		border: none;
		margin: 0;
		
		* {
			
			background: none;
			border: none;
			font-size: 1.5rem;
			padding: .5rem .5rem .5rem 0;
			
		}
				
	}
	
	.col-lg-10 {
		
		p + h2 {
		
			padding-top: 2rem;
			
		}
		
	}

}