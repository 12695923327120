/* Templates 
1) Overview Template e.g. Debt Recovery 
2) Letter Cycle Debt Recovery */

.page-links-block {
	
	.equal {

		background: #0b2d49;
		border-radius: 1rem;
		height: 100%;

	}
	
}

.post-card {

	margin-bottom: 3rem;

	@media (max-width: 768px) {
	
		&:last-of-type {
		
			margin-bottom: none;
		
		}
	
	}
	&__header{
		&.objectfit{
			height: unset;
		
			img{
				height: unset;
			}
			
		}

	}
	&__inner {

		border-radius: 1rem;
		height: 100%;
		
		img {
			
			border-radius: 1rem 1rem 0 0;
			
		}

	}

	&__content {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		padding: 2rem 2rem 3rem 2rem;
		position: relative;
		flex: 1;
		
		h3 {
		
			font-family: Montserrat,sans-serif !important;
			font-size: 2rem;
			
			&:before {
			
				content: url(../../assets/images/chevron-right-green.svg);
				height: 16px;
				left: 20px;
				position: absolute;
				width: 10px;
			
			}
		
		}
		
		h3, p, a {
		
			margin-left: 20px;
		
		}
		&>p{
			margin-top: 2.5rem;
			margin-bottom: 2rem;
		}

		a.btn {
			justify-self: flex-end;
			margin-top: auto;
			margin-bottom: 0;
		}

		.link {
			bottom: 2rem;
			color: #31beaf;
			position: absolute;
			
		}

	}
	
}