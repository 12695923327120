.hero {

	.hero__h1 {
		
		color: #31beaf;
		font-family: Montserrat,sans-serif;
		font-size: 1.5rem;
		font-weight: 400;
		margin-bottom: 1rem;
		text-transform: uppercase;
		
	}
	
	h2 {
	
		font-size: 5.8rem;
		
		@media (max-width: 1024px) {
			
			font-size: 4rem;
			
		}

		@media (max-width: 768px) {
			margin-bottom: 1rem;
		}
	}
	
	@media (max-width: 800px) {
		
		.col-md-1 {
		
			border: .2rem solid yellow;
			display: none;

		}
		
	}
	
	.strikethrough {
		
		color: #31beaf;
		
	}	
	
	/* Image Masks */

	.hero__chevron-small,
	.hero__chevron-large {

		height: 100%;
		/*-o-object-fit: cover;
		object-fit: cover;*/
		-o-object-position: left;
		object-position: left;
		position: absolute;
		top: 0;
		z-index: 1;

		@media (max-width: 1024px) {

			// display: none !important;

		}

		// @media (max-width: 1200px) {
		// 	height: 80%;
		// }

		// @media (max-width: 948px) {
		// 	height: 60%;
		// }

		// @media (max-width: 850px) {
		// 	display: none;
		// }

	}

	.hero__chevron-large {
		max-width: 100%;
	}

	.hero__chevron-small {

		right: 0;

		@media (max-width: 1024px) {
			right: 0;
		}
	}
	
}