.single {
	
	.article--return {
		
		padding: 2rem 0 2rem 1.5rem;
		
		a {
			
			text-decoration: none;
			
		}
		
	}
	
	
	
	.col-lg-6 {
				
		h2 {

			font-size: 1.5rem;
			font-weight: 600;
			margin: 2rem 0 -1.5rem 0;

			
		}
		
		.article--date {
			
			font-size: 1.3rem;
			
		}
		
		a {
			
			color: #31BEAF;
			
			&:hover {
				
				color: red;
				
			}
			
		}
		
		img {
			
			padding: 1rem 0 0;
			max-width: 100%;
			
		}
		
	}
	
	.block {

		.col-lg-12 h3 {
			
			margin-bottom: 6rem;
			
		}
		
		.inner-spacing {

			margin-bottom: 4rem;
			padding-bottom: 6rem;

		}

		.btn {

			bottom: 0;
			position: absolute;
			left: 1.4rem;

		}

	}
	
}