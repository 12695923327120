.video-carousel {
	.slick-slide{
		max-height: 500px;
		video{
			max-height: 500px;		}
	}
	&__arrows {
		
		text-align: center;
		margin: 3rem 0 5.5rem 0;
		
		button {

			background: none;
			border: none;

		}
		
	}
	
	&:before,
	&:after {

		bottom: .5rem;
		content: '';
		height: 5rem;
		left: -5rem;
		position: absolute;
		width: 5rem;
		z-index: 0;
		
		@media (max-width: 1350px) {
		
			display: none;
			
		}
	
	}
	
	&:after {
	
		left: auto;
		right: -5rem;
		
	}
		
}

.bg-light .video-carousel {

	&:before {
		 
		background: url(../../assets/images/shadow-left-blue-bg.png);
		
	}

	&:after {
	
		background: url(../../assets/images/shadow-right-blue-bg.png);

	}

}

.bg-split--light-to-white .video-carousel {

	&:before {
		 
		background: url(../../assets/images/shadow-left-white-bg.png);
		
	}

	&:after {
	
		background: url(../../assets/images/shadow-right-white-bg.png);

	}

}