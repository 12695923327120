.page-template-template-cant-pay {
	
	.objectfit {
		
		height: auto;
		
	}
	
	.row.m-b-60 {
		
		@media (max-width: 767px) {
		
			margin-top: 5rem;
		
		}
	
	}
	
	#team-grid {
		
		margin-bottom: 6rem;
		
		&.gridder--four {
						
			.gridder-show + .gridder__toggle {
				
				// margin-right: 2rem !important;
				
			}
		
		}
		
		.gridder__toggle {
			
			margin-bottom: 0 !important;
			
		}
		
		.agent-card {

			.gridder__content {

				border-radius: .5rem;

			}

			&__image {

				border-radius: .5rem;
				width: 100%;

			}

			&__text {

				padding: 2rem;
				width: 100%;

			}

			.color-teal {

				color: #31beaf;

			}
						
			&.selectedItem {
				
				.agent-card__text {

					background: #31beaf; 

				}

				.color-teal {

					color: #fff;

				}			
				
			}

		}
		
		.gridder-expanded-content {
			
			background: none;
			padding: 2rem;
			text-align: center;
			
			h4 {
				
				color: #31beaf;	
				
			}

		}
		
	}
	
}