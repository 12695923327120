[class^="direct-security"], [class*="direct-security"] {
		
	.more-links-footer {
		
		display: none;
		
	}
	
	.site-footer {

		padding-top: 5rem;
		
		&__top {
			
			img {
				
				max-width: 10rem;
			}
			
			p {
				
				display: none;
				
			}
			
		}
		
	}
	
}