//******************************************************************************
// HEADER
//******************************************************************************
.site-header {
	width: 100%;
	background-color: #fff;

	.navbar {
		padding: 1.5rem;
		.navbar-brand {
			.logo {

			}
		}
	}

	// TABLET
    @include media-breakpoint-only(lg) {
        .navbar {
			.navbar-brand {
				svg {
					width: 150px;
				}
			}
		}
    }

	// MOBILE
    @include media-breakpoint-between(xs, md) {
		.navbar {
			.navbar-brand {
				svg {
					width: 100px;
				}
			}
		}
	}
}

.mobile-call-menu {
	&__menu {
		display: none;
	}
}