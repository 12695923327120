.home {
	
	#content .bg-light {
		
		position: relative;
	
		&:before {
		
			background: #fff;
			bottom: 0;
			content: "";	
			display: block;
			height: 20%;
			position: absolute;
			top: 0;
			width: 100%;
			
			@media (max-width: 768px) {
				height: 23%;	
			}

			@media (max-width: 768px) {
				height: 17%;	

			}

			@media (max-width: 600px) {
				
				height: 14%;	
				
			}

			@media (max-width: 400px) {
				
				height: 12.5%;	
				
			}

		}
		
		.block-header {
			
			font-size: 4.5rem;
			
			@media (max-width: 767px) {
				font-size: 2.3rem;
				line-height: 3rem;
			}
		}

		
	}

	#content > .container.block {
		@media (max-width: 1024px) {
		    padding-top: 7rem;
		}

		@media (max-width: 767px) {
		    padding-top: 7rem;
		}
	}
	
	.video-carousel__arrows + .row > div {
	
		@media (max-width: 800px) {
			
			flex: 0 0 100%;
			margin-top: 0;
			max-width: 100%;
			width: 100%;
			
		}
		
	}

	.latest-news {

		p a:not(.btn) {
			display: none;
		}

		.cards {

			@media (min-width: 768px) and (max-width: 1349.98px) {
				div:nth-child(3) {
					display: none;
				}
			}

		}

	}
	
}