.form-container {

	border-radius: .5rem;
	padding: 3rem;
	
	.vertical_radio {
		
		
	}
	
	.frm_button_submit {
		
		background: #0b2d49 !important;
		border: none !important;
		padding: 1rem 5rem 1rem 2rem!important;
		position: relative;
		
		&:after {
			
			content: url(../../assets/images/button-arrow-right-white.svg);
			position: absolute;
			right: 2rem;

		}		
		
	}
	&.bg-opposite{
		position: relative;
		
		@media screen and (min-width: 1024px){
			// min-height: 110%;
		}

		@media screen and (max-width: 1024px){
			// min-height: 105%;
		}

		@media screen and (max-width: 667px){
			// min-height: 110%;
		 //    margin-top: -40rem;
		}

		@media screen and (max-width: 450px){
		    // margin-top: -30rem;
		}

		.frm_fields_container {
			> .frm_submit {
			    display: block;
			}
		}
	}
	
	&__accreditations {
		position: relative;
	    width: 100%;
	    padding-bottom: 0;
        // margin-bottom: -7rem;

		strong {
			
			font-weight: 600;
			
		}
		
	}

	&.reset-absolute-positioning {
		min-height: auto;

		.form-container__accreditations {
			position: relative;
		    width: 100%;
		    padding-bottom: 0;
	        margin-bottom: 0;
		}
	}
	
}

.cta-container {
	@extend .form-container;

	// margin-bottom: -10rem;

	img {
		margin-top: 2rem;
	} 
}

.frm_error {
	
	margin-top: .5rem;
		
}

select {
	
	appearance: none;
	background: #fff url(../../assets/images/select-arrow.svg) no-repeat calc(100% - 1rem) center;
	border: .1rem solid #9B9B9B;
	border-radius: .5rem;
	font-weight: 400;
	
}

.searchandfilter {
	
	input {
	
		border: .1rem solid #9B9B9B;
		border-radius: .5rem;
		
	}

	.sf-field-search {

		label {

			width: 100%;

		}
		
	}


	input[type="checkbox"] {

		width: 20px;
		height: 20px;

	}


}

#frm_field_783_container {
	display: flex;
}

.frm_dropzone .dz-preview .dz-column {
	padding-right: 20px;
}

.frm_dropzone {
	.dz-complete {
		border: 3px solid #31beaf;
		border-bottom: 3px solid #31beaf;

		&:last-child {
			border: 3px solid #31beaf;
			background: #fff url(../../assets/images/green-tick.svg) no-repeat calc(100% - 1rem) 10%;
		}
	}

	.dz-clickable.frm_single_upload .dz-message {
		margin: 0;
	}
}

.frm_style_formidable-style.with_frm_style .form-field {
	@media screen and (max-width: 768px) {
    	grid-column: span 12 / span 12;
   	}
}