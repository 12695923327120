.error404 {
	
	background: #0c304e url(../../assets/images/404-background.png);
	background-size: cover;

	.error404 {
		
		align-content: center;
		align-items: center;
		height: calc(100vh - 8rem);
		display: flex;
		justify-content: center;
		
		@media (max-width: 768px) {

			height: calc(100vh - 6.4rem);

		}
		
		&__inner {

			max-width: 72rem;
			padding: 0 1.5rem;

		}
	
		&__image {

			float: left;
			margin-right: 2rem;
			width: 11.6rem;
			
			@media (max-width: 768px) {
			
				float: none;
				margin-bottom: 2rem;
			
			}
			
		}
		
		&__content {
			
			@media (min-width: 768px) {
			
				float: left;
				width: calc(100% - (11.6rem + 2rem);
				
			}
			
		}

	}
	
	h1 {
		
		font-size: 7rem;
		color: #fff;
		margin-bottom: 5rem;
		
		@media (max-width: 768px) {

			font-size: 4rem;

		}
		
	}
	
	h2 {
		
		color: #31beaf;
		font-size: 7rem;
		font-weight: 300;
		
		@media (max-width: 768px) {

			font-size: 4rem;

		}		
		
	}
	
	p {
		
		color: #fff;
		margin: 0 0 4rem 0;
		
	}
	
	.more-links-footer,
	footer {
		
		display: none;
	
	}
	
}