//******************************************************************************
// FORMS & ERRORS
//******************************************************************************

// Base Inputs
// -----------------------------------------------------------------------------
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea {
    width: 100%;
    min-width: 100px;
    height: 50px;
    font-size: 16px;
    padding: 0 18px;
    @media (max-width: 360px){
        &::placeholder{
            font-size: 1.2rem;
        }
    }
    
}

input[type="checkbox"] {

    border: 1px solid #cbcbc7;
    background: #fff;
    top: 3px;
    padding: .1rem;
    display: inline-block;
    vertical-align: middle;
    appearance: none;

    &:checked {
        background: var(--primary);
    }
}


// Placeholder
// -----------------------------------------------------------------------------
::-webkit-input-placeholder {
    color: #ddd;
	font-family: Montserrat,sans-serif;
	font-size: 1.5rem;
	font-style: italic;
}
::-moz-placeholder {
    color: #ddd;
	font-family: Montserrat,sans-serif;
	font-size: 1.5rem;
	font-style: italic;
}
:-ms-input-placeholder {
    color: #ddd;
	font-family: Montserrat,sans-serif;
	font-size: 1.5rem;
	font-style: italic;
}
:-moz-placeholder {
    color: #ddd;
	font-family: Montserrat,sans-serif;
	font-size: 1.5rem;
	font-style: italic;
}

// Styles
// -----------------------------------------------------------------------------
.form {
    padding: 4rem;

    &--filter {
        ul {
            padding: 0;
        }
    }
}

.frm_fields_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-bottom: 0;

    > .frm_form_field,
    > .frm_submit {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    > .frm_submit{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .frm_button_submit:last-child{
            margin-left: auto;
        }
    }
}

.frm_form_field {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 2rem;
}
.frm_half {
    max-width: 50%;
    flex-basis: 50%;

    @include media-breakpoint-down(sm) {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.frm_description {
    font-size: 1.3rem;
    opacity: .8;
}

.frm_none_container {
    .frm_primary_label {
        display: none;
    }
}

.frm_screen_reader,
.frm_verify {
    display: none;
}

.frm_error_style {
    margin-bottom: 3rem;
}

.frm_error,
.frm_error_style {
    color: red;
}


.multistep-form {
    position: relative;
    
    .multistep-form-wrapper{
        display: grid;
        grid-template-columns: repeat(12, 6.5%);
        grid-auto-rows: max-content;
        grid-gap: 0 2%;
        background-color: #e6eaed;
        border-radius: 1rem;
        padding: 3rem;
        .frm_form_field:last-of-type{
            margin:0;
        }
    }

    .frm_fields_container {
        // max-width: 1170px;
        max-width: 1140px;
        margin: 4rem auto 0;

        @include media-breakpoint-between(sm,md) {
            margin: 4rem 1.5rem 0 1.5rem;
        }

        @include media-breakpoint-only(md) {
            max-width: 95vw;
            // max-width: 1296px;
        }
        @include media-breakpoint-only(sm) {
        }

    }

    .frm_rootline_group {
        background: $blue;
        width: 100%;
        position: absolute;
        text-align: center;
        left: 0;
        top: 0;
        margin-top: 0;
        z-index: 1;
        @media screen and (min-width: 1280px){
            margin-top: -5rem;
            padding-bottom: 4rem;
        }
        
    }

    &.payment {
        max-width: 1170px;
        margin: 0 auto;
        padding-bottom: 3rem;

        .payment-methods {
            padding: 4rem 2rem;
            text-align: center;
            background-color: $blue;
            border-radius: 1rem;
            color: #fff;
            margin-bottom: 4rem;
            > * + * {
                margin-top: 2rem;
                margin-bottom: 0;
            }
        }
        .payment-methods-title {
            font-size: 23px;
            line-height: 1.2;
            font-family: "Source Sans Pro",sans-serif;
            font-weight: 700;
            margin-bottom: 0;
        }
        .frm_fields_container {
            margin-top: 0;
        }
    }
}

.frm_image_options {
    .frm_image_option_container {
        background: $teal;
    }

    input[type="radio"]:checked + .frm_image_option_container,
    .frm_image_option_container:hover {
        background: $blue;
        color: white;

        svg path[fill="#0b2d49"] {
            fill: $teal;
        }
    }
}