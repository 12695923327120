//******************************************************************************
// PAGES
// Common styles shared by multiple pages
// Unless you're working on very large site or application, most of the
// page-specific code should go in here.
//******************************************************************************

// PAGE > HOME
// -----------------------------------------------------------------------------

//PAGE > THANKYOU
.thankyou-message{
	padding-bottom: 6rem;
	text-align: center;
	button{
		margin-top: 2rem;
	}
}


.big-list {
	list-style: none;
	padding: 0;
	
	li {
		
		font-family: "Source Sans Pro",sans-serif;
		font-size: 2.1rem;
		line-height: 2.9rem;
		font-weight: 700;
		margin-bottom: 4rem;
		margin-top: 4rem;
		padding-left: 6rem;
		position: relative;
		
		@media (max-width: 768px) {
		
			font-size: 2rem;
		
		}
		
		&:last-of-type {
		
			margin-bottom: 0;
		
		}
		
		.big-list__icon {
			display: block;
			float: left;
			left: 0;
			position: absolute;
			top: 1.5rem;
			transform: translateY(-50%);
			
			img {
				display: block;
				margin: 0;
			}
		}
		
	}
	
}
