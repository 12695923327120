//******************************************************************************
// BRAND & SKINS
// colors, specific typography, logos
//******************************************************************************

$bg-white: #fff;
$blue: #0B2D49;
$light: #E6EAED;
$teal: #31BEAF;

:root {
    --primary: $blue;
}

.bg-teal,
.bg--teal {
    background: $teal;
    &.card--stat{
        background-color: transparent;
        .card__frontside{
            background-color: $teal;
            height: 100%;
        }
    }
}

.bg-blue,
.bg--blue {
    background: $blue;
    color: white;
}

.bg-white,
.bg--white {
    background-color: $bg-white;

    .bg-opposite {
        background: $light;
    }
}
.bg-primary {
    background-color: var(--primary) !important;
}
.bg-primary-light {
    background-color: var(--primary-light) !important;
}
.bg-accent {
    background-color: var(--accent) !important;
}
.bg-light, 
.bg--light, {
	background-color: $light !important;
    color: $blue;

    .bg-opposite {
        background: white;
    }

    .chevron-divider__image {
        background-color: $light !important;
    }
}

.bg-split {
    &--light-to-white {
        position: relative;
        background-color: $light;
        
        &:before {
            content: "";
            position: absolute;
            background: $white;
            height: 50%;
            bottom: 0;
            width: 100%;
            display: block;
        }
    }

    &--white-to-light {
        position: relative;
        background-color: white;
        
        &:before {
            content: "";
            position: absolute;
            background: $light;
            height: 50%;
            bottom: 0;
            width: 100%;
            display: block;
        }
    }

    &--blue-to-white {
        position: relative;
        background-color: $blue;
        
        &:before {
            content: "";
            position: absolute;
            background: $white;
            height: 50%;
            bottom: 0;
            width: 100%;
            display: block;
        }
    }

    &--white-to-blue {
        position: relative;
        background-color: white;
        
        &:before {
            content: "";
            position: absolute;
            background: $blue;
            height: 50%;
            bottom: 0;
            width: 100%;
            display: block;
        }
    }
	
    &--light-to-blue {
        position: relative;
        background-color: $light;
        
        &:before {
            content: "";
            position: absolute;
            background: $blue;
            height: 50%;
            bottom: 0;
            width: 100%;
            display: block;
        }
    }
	
    &--blue-to-light {
        position: relative;
        background-color: $blue;
        
        &:before {
            content: "";
            position: absolute;
            background: $light;
            height: 50%;
            bottom: 0;
            width: 100%;
            display: block;
        }
    }
	
}